/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --default-button-font-family: var(--base-font-family);
    --default-button-font-weight: #{$font-weight-semibold};
    --default-button-text-transform: var(--base-secondary-text-transform);
    --default-button-font-size: 17px;
    --default-button-letter-spacing: #{$letter-spacing1};
    --default-text-decoration: none;
    --button-border-width: 1.5px;
    --button-text-transform: var(--base-secondary-text-transform);
    --button-text-align: center;
    --button-height: 44px;
    --button-padding: 0 20px;
    --button-small-height: 25px;
    --button-small-padding: 0 25px;
    --button-border-radius: var(--general-border-radius);

    // Primary button
    --button-background: var(--primary-light-color);
    --button-border: var(--primary-light-color);
    --button-color: var(--color-white);
    --button-text-decoration: none;

    // Primary button (Hover)
    --button-hover-background: var(--secondary-dark-color);
    --button-hover-border: var(--secondary-dark-color);
    --button-hover-color: var(--color-white);

    // Hollow button (Hollow)
    --hollow-button-background: var(--color-white);
    --hollow-button-border: var(--primary-dark-color);
    --hollow-button-color: var(--primary-dark-color);
    
    // Hollow button (Hover)
    --hollow-button-hover-background: var(--color-white);
    --hollow-button-hover-border: var(--primary-light-color);
    --hollow-button-hover-color: var(--primary-light-color);

    // Secondary button
    --secondary-button-background: var(--primary-base-color);
    --secondary-button-border: var(--primary-base-color);
    --secondary-button-color: var(--color-white);
        
    // Secondary button (Hover)
    --secondary-button-hover-background: var(--color-blue3);
    --secondary-button-hover-border: var(--color-blue3);
    --secondary-button-hover-color: var(--color-white);

    // Secondary Revert button
    --secondary-revert-button-background: tranparent;
    --secondary-revert-button-border: var(--color-white);
    --secondary-revert-button-color: var(--color-white);
        
    // Secondary Revert button (Hover)
    --secondary-revert-button-hover-background: var(--color-white);
    --secondary-revert-button-hover-border: var(--color-white);
    --secondary-revert-button-hover-color: var(--primary-base-color);

    // Button like link
    --button-like-link-font-size: var(--base-secondary-font-size);
    --button-like-link-font-weight: #{$font-weight-medium};
    --button-like-link-letter-spacing: 0;
    --button-like-link-padding: 0;
    --button-like-link-color: var(--general-link-color);

    // Button like link (Hover)
    --button-like-link-hover-color: var(--general-link-color);
    --button-like-link-hover-text-decoration: underline;

    // Button like link style 2
    --button-like-link2-font-size: 12px;
    --button-like-link2-font-weight: #{$font-weight-medium};
    --button-like-link2-letter-spacing: #{$letter-spacing5};
    --button-like-link2-padding: 0;
    --button-like-link2-color: var(--general-link-color);

    // Button like link style 2 (Hover)
    --button-like-link2-hover-color: var(--primary-error-color);
    --button-like-link2-hover-text-decoration: none;
}

.Button {
    @include button;
}

button {
    background-color: transparent;
    
    @include desktop {
        transition: var(--general-icon-animation);
    }

    // override default blue color for lists in Safari since iOS15

    & * {
        color: var(--general-link-color);
    }
}
