/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.HomepageCategories {
    @include desktop {
        grid-gap: 20px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-block-start: 20px;
    }

    &-Figure {
        @include mobile {
            margin-top: 10px;
            min-height: 200px;
        }

        @include desktop {
            min-height: 228px;
        }

        @include desktop-xl {
            min-height: 300px;
        }

        &:hover {
            @include desktop {
                img {
                    @include image-overlay;
                }

                .HomepageCategories-Figcaption {
                    opacity: var(--general-image-hover-opacity);
                }
            }
        }

        .Image {
            display: flex;
            padding-bottom: 0;
            height: auto;

            img {
                transition: var(--general-icon-animation);
                object-fit: cover;
            }
        }
    }

    &-Figcaption {
        --button-color: var(--color-white);
        --hollow-button-background: var(--color-white);
        --hollow-button-hover-background: var(--color-white);
        --hollow-button-hover-height: none;

        position: absolute;
        top: 10px;
        text-align: left;
        pointer-events: none;

        @include desktop {
            width: 100%;
            padding-left: 30px;
            padding-right: 30px;
            top: 14px;
        }

        @include mobile {
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }

        &_isPlaceholder {
            padding-block-end: 31px;
        }
    }

    &-Button {
        @include mobile {
            width: auto;
        }
    }
}
