/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --search-overlay-desktop-width: 378px;
    --search-overlay-border-radius: var(--general-border-radius);
    --search-criteria-background: var(--secondary-base-color);
    --search-criteria-label-color: rgba(10, 10, 10, .44);
    --search-criteria-search-term-color: #0a0a0a;
}

.SearchOverlay {
    &_isVisible {
        .SearchOverlay {
            &-Results {
                display: block;
            }
        }
    }

    &-Results {
        background: var(--color-white);
        display: none;
        z-index: 110;
        width: 100%;

        @include desktop {
            padding-inline-end: 0;
            position: absolute;
            box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
            width: var(--search-overlay-desktop-width);
            right: 0;
            border-radius: var(--search-overlay-border-radius);
            margin-top: 5px;

            ul {
                max-height: 350px;
                overflow: hidden;
                overflow-y: auto;
                padding: 20px 0;
            }
        }

        .NoResults {
            padding: 20px;
            margin: 0;
            font-size: var(--base-secondary-font-size);
            line-height: 18px;
            letter-spacing: $letter-spacing2;
        }
    }
}
