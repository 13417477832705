/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --star-size: 18px;
    --plus-minus-icon-size: 24px;
}

@mixin icon-black-hover-effect {
    fill: var(--color-black);

    &:hover {
        fill: var(--primary-base-color);
    }
}

@mixin icon-primary {
    fill: var(--primary-base-color);

    @include hoverable { 
        &:hover {
            fill: var(--primary-dark-color);
        }
    }

    &:active {
        fill: var(--primary-dark-color);
    }
}

@mixin not-available {
    content: '';
    position: absolute;
    width: 1px;
    height: 32px;
    transform-origin: 50% 50%;
    inset-inline-start: 15px;
    inset-block-start: 1px;
    transform: rotate(45deg);
    box-shadow: none;
    border-radius: 0;
    opacity: 1;
}

@mixin ios-share {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e943";
        font-size: 15px;
        line-height: 15px;
        color: var(--general-icon-color);
        font-weight: normal;
    }
}

@mixin ios-plus {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e916";
        font-size: 15px;
        line-height: 15px;
        color: var(--color-white);
        font-weight: normal;
    }
}

@mixin rtl-icon {
    [dir="rtl"] & {
        transform: scaleX(-1);
    }
}

// Custom Icons

@mixin dropdown-up-arrow-icon {
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: -6px;
        height: 10px;
        width: 10px;
        transform: rotate(45deg);
        background-color: var(--color-white);
        box-shadow: var(--field-select-box-shadow);
        border-inline-start: 1px solid transparent;
        border-block-start: 1px solid transparent;
        z-index: 2;
    }
}

@mixin search-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e902";
        font-size: 17px;
        line-height: 17px;
        color: var(--general-icon-color);
        font-weight: normal;
    }
}

@mixin close-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e901";
        font-size: 15px;
        line-height: 15px;
        color: var(--general-icon-color);
        font-weight: normal;
    }
}

@mixin close-bold-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e94b";
        font-size: 15px;
        line-height: 15px;
        color: var(--general-icon-color);
        font-weight: normal;
    }
}

@mixin top-down-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e90f";
        font-size: 8px;
        line-height: 11px;
        color: var(--header-top-menu-color);
        font-weight: normal;
    }
}

@mixin top-up-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e90d";
        font-size: 8px;
        line-height: 11px;
        color: var(--header-top-menu-color);
        font-weight: normal;
    }
}

@mixin arrow-right-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e932";
        font-size: 14px;
        line-height: 14px;
        color: var(--menu-sub-item-right-arrow-color);
        font-weight: normal;
    }
}

@mixin arrow-left-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e933";
        font-size: 14px;
        line-height: 14px;
        color: var(--menu-sub-item-right-arrow-color);
        font-weight: normal;
    }
}

@mixin arrow-down-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e935";
        font-size: 5px;
        line-height: 7px;
        color: var(--header-top-menu-color);
        font-weight: $font-weight-extrabold;
    }
}

@mixin arrow-up-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e922";
        font-size: 5px;
        line-height: 7px;
        color: var(--header-top-menu-color);
        font-weight: $font-weight-extrabold;
    }
}

@mixin go-back-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e933";
        font-size: 18px;
        line-height: 18px;
        color: var(--menu-sub-item-right-arrow-color);
        font-weight: normal;
    }
}

@mixin account-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e946";
        font-size: var(--header-icons-width);
        line-height: var(--header-icons-width);
        color: var(--general-icon-color);
        font-weight: normal;
    }
}

@mixin minicart-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e900";
        font-size: var(--header-icons-width);
        line-height: var(--header-icons-width);
        color: var(--general-icon-color);
        font-weight: normal;
    }
}

@mixin lock-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e92d";
        font-size: var(--notification-icon-size);
        line-height: var(--notification-icon-size);
        color: var(--general-background-color);
        font-weight: normal;
    }
}

@mixin checkout-lock-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e94c";
        font-size: 16px;
        line-height: 16px;
        color: var(--cart-count-color);
        font-weight: normal;
    }
}

@mixin delete-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e92f";
        font-size: var(--notification-icon-size);
        line-height: var(--notification-icon-size);
        color: var(--input-general-border-color);
        font-weight: normal;
    }
}

@mixin qty-plus-thin-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e916";
        font-size: 9px;
        line-height: 9px;
        font-weight: normal;
        color: var(--color-white);
        margin-left: 1px;
    }
}

@mixin qty-minus-thin-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e92a";
        font-size: 1.5px;
        line-height: 3px;
        font-weight: normal;
        color: var(--color-white);
        margin-left: 1px;
    }
}

@mixin insta-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e929";
        font-size: 24px;
        line-height: 24px;
        font-weight: normal;
        color: var(--primary-light-color);
    }
}

@mixin facebook-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e904";
        font-size: 24px;
        line-height: 24px;
        font-weight: normal;
        color: var(--primary-light-color);
    }
}

@mixin twitter-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e90a";
        font-size: 20px;
        line-height: 20px;
        font-weight: normal;
        color: var(--primary-light-color);
    }
}

@mixin youtube-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e934";
        font-size: 20px;
        line-height: 20px;
        font-weight: normal;
        color: var(--primary-light-color);
    }
}

@mixin email-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e93e";
        font-size: 16px;
        line-height: 16px;
        font-weight: normal;
        color: var(--general-icon-hover-color);
        transition: var(--general-icon-animation);
    }
}

@mixin wishlist-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e93c";
        font-size: 16px;
        line-height: 16px;
        font-weight: normal;
        color: var(--product-action-icon-color);
        transition: var(--general-icon-animation);
    }
}

@mixin wishlist-icon-active {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e93d";
        font-size: 16px;
        line-height: 16px;
        font-weight: normal;
        color: var(--product-action-icon-active-color);
    }
}

@mixin compare-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e93b";
        font-size: 14px;
        line-height: 14px;
        font-weight: normal;
        color: var(--product-action-icon-color);
        transition: var(--general-icon-animation);
    }
}

@mixin shop-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e903";
        font-size: var(--menu-mobile-tab-home-icon-size);
        line-height: var(--menu-mobile-tab-home-icon-size);
        font-weight: normal;
        color: var(--menu-mobile-tab-icon-color);
        transition: var(--general-icon-animation);
    }
}

@mixin home-mobile-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e949";
        font-size: var(--menu-mobile-tab-home-icon-size);
        line-height: var(--menu-mobile-tab-home-icon-size);
        color: var(--menu-mobile-tab-icon-color);
        font-weight: normal;
    }
}

@mixin menu-mobile-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e942";
        font-size: var(--menu-mobile-tab-menu-icon-size);
        line-height: var(--menu-mobile-tab-menu-icon-size);
        color: var(--menu-mobile-tab-icon-color);
        font-weight: normal;
    }
}

@mixin account-mobile-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e946";
        font-size: var(--menu-mobile-tab-user-icon-size);
        line-height: var(--menu-mobile-tab-user-icon-size);
        color: var(--menu-mobile-tab-icon-color);
        font-weight: normal;
    }
}

@mixin minicart-mobile-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e900";
        font-size: var(--menu-mobile-tab-minicart-icon-size);
        line-height: var(--menu-mobile-tab-minicart-icon-size);
        color: var(--menu-mobile-tab-icon-color);
        font-weight: normal;
    }
}

@mixin filter-checked-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e919";
        font-size: 13px;
        line-height: 13px;
        font-weight: normal;
        color: var(--primary-light-color);
        transition: var(--general-icon-animation);
    }
}

@mixin checkout-checked-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e919";
        font-size: 13px;
        line-height: 13px;
        font-weight: normal;
        color: var(--input-general-background-color);
        transition: var(--general-icon-animation);
    }
}

@mixin filter-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e907";
        font-size: 15px;
        line-height: 16px;
        font-weight: normal;
        color: var(--product-action-icon-color);
        transition: var(--general-icon-animation);
    }
}

@mixin sort-by-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e94a";
        font-size: 13px;
        line-height: 16px;
        font-weight: normal;
        color: var(--product-action-icon-color);
        transition: var(--general-icon-animation);
    }
}

@mixin success-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e90b";
        font-size: var(--notification-icon-size);
        line-height: var(--notification-icon-size);
        font-weight: normal;
        color: var(--notification-success-color);
    }
}

@mixin error-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e90e";
        font-size: var(--notification-icon-size);
        line-height: var(--notification-icon-size);
        font-weight: normal;
        color: var(--notification-error-color);
    }
}

@mixin info-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e918";
        font-size: var(--notification-icon-size);
        line-height: var(--notification-icon-size);
        font-weight: normal;
        color: var(--notification-info-color);
    }
}

@mixin discount-code-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e915";
        font-size: 16px;
        line-height: 17px;
        font-weight: normal;
        color: var(--product-action-icon-color);
    }
}

@mixin share-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e93a";
        font-size: 20px;
        line-height: 22px;
        font-weight: normal;
        color: var(--product-action-icon-color);
    }
}

@mixin chat-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e913";
        font-size: 20px;
        line-height: 22px;
        font-weight: normal;
        color: var(--primary-dark-color);
    }
}

@mixin contact-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e93e";
        font-size: 15px;
        line-height: 22px;
        font-weight: normal;
        color: var(--primary-dark-color);
    }
}

@mixin telephone-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e944";
        font-size: 19px;
        line-height: 22px;
        font-weight: normal;
        color: var(--primary-dark-color);
    }
}

// Variables

$icon-secure-checkout: "\e94c";
$icon-close-bold: "\e94b";
$icon-sort: "\e94a";
$icon-menu1: "\e942";
$icon-account: "\e946";
$icon-home: "\e949";
$icon-feather-mail: "\e93e";
$icon-lock: "\e92d";
$icon-success: "\e90b";
$icon-close: "\e901";
$icon-search: "\e902";
$icon-error: "\e90e";
$icon-info: "\e918";
$icon-enlarger: "\e920";
$icon-link: "\e927";
$icon-show: "\e928";
$icon-hide: "\e92b";
$icon-wishlist: "\e92e";
$icon-delete: "\e92f";
$icon-play-path1: "\e930";
$icon-play-path2: "\e931";
$icon-arrow-right: "\e932";
$icon-arrow-left: "\e933";
$icon-ruler: "\e936";
$icon-share: "\e93a";
$icon-compare: "\e93b";
$icon-heart: "\e93c";
$icon-heart-filled: "\e93d";
$icon-shop: "\e903";
$icon-down-arrow-box: "\e90f";
$icon-up-arrow-box: "\e90d";
$icon-checkmark: "\e919";
$icon-truck: "\e924";
$icon-email: "\e925";
$icon-arrow-right-thin: "\e926";
$icon-cart: "\e900";
$icon-comment: "\e913";
$icon-contact-location: "\e945";
$icon-coupon: "\e93f";
$icon-filters: "\e907";
$icon-attachment: "\e911";
$icon-expand-filters: "\e916";
$icon-location-picker: "\e906";
$icon-my-account: "\e912";
$icon-telephone: "\e914";
$icon-youtube: "\e934";
$icon-question-circle: "\f059";
$icon-star: "\f005";
$icon-star-o: "\f006";
$icon-calendar: "\e948";
$icon-order-tracker: "\e947";
$icon-share-wishlist: "\e943";
$icon-billing: "\e940";
$icon-shipping: "\e941";
$icon-star-rating: "\e939";
$icon-menu-m: "\e937";
$icon-virtual: "\e938";
$icon-angle-up: "\e922";
$icon-angle-down: "\e935";
$icon-arrow: "\e923";
$icon-insta: "\e929";
$icon-minus-large: "\e92a";
$icon-whatsapp: "\e92c";
$icon-send-email: "\e921";
$icon-minus-faq: "\e91f";
$icon-checkbox-checked: "\e91d";
$icon-checkbox: "\e91e";
$icon-radio-button-checked: "\e91b";
$icon-radio-button: "\e91c";
$icon-review-star: "\e91a";
$icon-size-guide: "\e917";
$icon-gift: "\e915";
$icon-information: "\e910";
$icon-copyright: "\e90c";
$icon-rss: "\e909";
$icon-twitter: "\e90a";
$icon-facebook: "\e904";
$icon-google: "\e905";
$icon-minus-icon: "\e908";
$icon-help: "\e623";
$icon-menu: "\e609";
$icon-contact-phone: "\e944";
