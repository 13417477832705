/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.StoreItem {
    &-Item {
        width: 100%;
        cursor: pointer;
        font-size: var(--field-select-option-item-font-size);
        letter-spacing: var(--field-select-option-item-letter-spacing);
        color: var(--field-select-option-item-color);
        padding: var(--field-select-option-item-padding);
        text-align: left;

        &:hover {
            @include desktop {
                background: var(--field-select-option-item-hover-background);
            }
        }

        &:last-of-type {
            @include desktop {
                border-radius: var(--field-select-border-radius);
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }
}
