/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.home-product-widget {
    &-title {
        padding-left: 20px;
        margin-bottom: 20px;

        @include title-dot-before;

        &::before {
            position: absolute;
            left: 0;
            top: 15px;
        }
    }

    .NewProducts {
        > h2 {
            display: none;
        }
    }
}
