/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.CurrencySwitcher {
    --selector-width: 72px;
    --selector-dropdown-width: 78px;

    border-block-end: 1px solid var(--primary-divider-color);

    @include mobile {
        width: 100%;
        order: 3;
        margin-left: var(--layout-mobile-vertical-padding);
        margin-right: var(--layout-mobile-vertical-padding);
        padding-block: var(--layout-mobile-vertical-padding);
    }

    .Field {
        margin-block-start: 0;

        &Select {
            @include desktop {
                width: var(--selector-width);
            }

            .ChevronIcon {
                inset-inline-end: 0;
            }

            &-Select {
                border: none;
                margin: 0;
                opacity: 1;
                padding-inline-start: 0;
                line-height: 19px;
                min-height: 0;

                @include mobile {
                    background-color: transparent;
                }

                &:focus {
                    @include mobile {
                        box-shadow: none;
                    }
                }
            }

            &-Clickable {
                &::after {
                    font-size: var(--header-top-menu-icon-size);
                }

                .FieldSelect-Select {
                    @include desktop {
                        text-align: right;
                        padding-right: 12px;
                    }
                }
            }

            &_isExpanded {
                .FieldSelect-Clickable {
                    text-decoration: underline;

                    &::after {
                        font-size: var(--header-top-menu-icon-size);
                    }
                }
            }

            &-Options {
                overflow: visible;

                &_isExpanded {
                    justify-content: center;

                    @include dropdown-up-arrow-icon;
                }
            }
        }
    }

    @include desktop {
        border-block-end: none;

        .FieldSelect {
            &-Select {
                --input-color: var(--header-top-menu-color);

                border: none;
                font-size: var(--header-top-menu-font-size);
                font-weight: var(--header-top-menu-font-weight);
                padding: 0;
            }

            &-Clickable {
                &::after {
                    right: 0;
                    top: 4px;
                }
            }

            &-Options {
                min-width: var(--selector-dropdown-width);
                width: min-content;

                &Wrapper {
                    min-width: var(--selector-dropdown-width);
                    width: min-content;
                    padding-top: 20px;
                    padding-bottom: 20px;
                }
            }
        }
    }
}
