/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    // caption
    --caption-font-size: var(--imported_caption_font_size, 12px);
    --caption-font-weight: var(--imported_caption_font_weight, 400);
    --caption-color: var(--imported_caption_color, #{$black});
    --caption-line-height: var(--imported_caption_line_height, 20px);
    --caption-text-transform: var(--imported_caption_text_transform, none);
    --caption-font-style: var(--imported_caption_font_style, normal);
}

* {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-family: inherit;
    outline: 0;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
    // backface-visibility: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;

    @media print {
        /* stylelint-disable-next-line declaration-no-important */
        color: #000000 !important;
        /* stylelint-disable-next-line declaration-no-important */
        filter: none !important;
        /* stylelint-disable-next-line declaration-no-important */
        text-shadow: none !important;
    }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
label,
summary {
    display: block;
}

header,
footer,
main {
    font-size: var(--base-primary-font-size);
}

main {
    --border-width: 1px;

    min-height: calc(100vh - var(--header-height) - var(--breadcrumbs-height));
    max-width: 100vw;

    @include mobile {
        min-height: calc(100vh - var(--header-total-height) - var(--navigation-tabs-height) + 2 * var(--border-width));
        margin-block-start: var(--header-total-height);
        margin-block-end: var(--footer-total-height);
    }

    @include desktop {
        min-height: calc(100vh - 470px);
    }

    &.MenuPage {
        @include mobile {
            background-color: var(--menu-mobile-background);
        }
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    margin-block-end: 20px;
}

img {
    width: 100%;
}

html {
    font-size: $font-standard-size;
}

body {
    font-family: $base-font-family;
    font-weight: $font-weight-regular;
    color: var(--general-paragraph-color);
    scroll-behavior: smooth;
    background-color: var(--general-background-color);

    @include print-only {
        background-color: transparent;
    }
}

a {
    color: var(--general-link-color);
    text-decoration: inherit;
    transition: $transition2;

    &:hover {
        cursor: pointer;
    }
}

li,
dl {
    @include paragraph;
}

li {
    list-style-type: none;
    list-style-position: inside;
    margin-block-end: 0;

    ol & {
        counter-increment: list;

        @include mobile {
            counter-increment: list;
        }
    }

    &:last-child {
        margin-block-end: 20px;
    }
}

p {
    margin-block-end: 15px;

    &.caption {
        color: var(--caption-color);
        font-size: var(--caption-font-size);
        font-style: var(--caption-font-style);
        font-weight: var(--caption-font-weight);
        line-height: var(--caption-line-height);
        text-transform: var(--caption-text-transform);
    }
}

input,
select {
    min-height: var(--input-height);
    line-height: var(--input-height);
    min-width: 56px;
}

input,
select,
textarea {
    font-size: var(--base-primary-font-size);
}

textarea {
    resize: none;
}

mark {
    margin-right: 5px;
}

iframe {
    max-width: 100%;
}

a,
input,
button,
textarea,
select,
details {
    @include mobile {
        touch-action: manipulation;
    }
}

details {
    @include mobile {
        border-top: 1px solid var(--general-paragraph-color);
        border-bottom: 1px solid var(--general-paragraph-color);
        margin-bottom: -1px;
    }    

    > summary {
        list-style: none;
        
        @include mobile {
            padding: 20px 0;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include top-down-icon;
        }

        &::-webkit-details-marker {
            display: none;
        }
    }

    &[open] {
        > summary {
            @include mobile {
                @include top-up-icon;
            }
        }
    }
}
