/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --default-input-padding: 15px 20px 14px;
    --input-background-color: var(--input-general-background-color);
    --input-border-color: var(--input-general-border-color);
    --input-border-color-focus: var(--input-general-border-color-focus);
    --input-shadow-color-focus: var(--input-general-shadow-color-focus);
    --input-border-radius: var(--general-border-radius);
    --input-padding: 0 15px;
    --input-color: var(--input-general-text-color);
    --input-placeholder-color: var(--input-general-placeholder-color);
    --input-type-number-width: 28px;
    --input-height: var(--input-general-height);
    --input-letter-spacing: var(--input-general-letter-spacing);
}

input,
textarea,
select {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-color);
    border-radius: var(--input-border-radius);
    color: var(--input-color);
    padding: var(--input-padding);
    appearance: none;
    letter-spacing: var(--input-letter-spacing);
    caret-color: var(--input-color);
    transition: var(--general-icon-animation);

    &:hover {
        @include desktop {
            border-color: var(--secondary-light-color);
        }
    }

    &:focus {
        @include desktop {
            border-color: var(--input-border-color-focus);
            box-shadow: var(--input-shadow-color-focus);
        }
    }

    &:not(:first-of-type) {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }
    }

    &:-webkit-autofill {
        /* stylelint-disable-next-line declaration-no-important */
        box-shadow: 0 0 0 30px var(--input-background-color) inset !important;
        /* stylelint-disable-next-line declaration-no-important */
        background-color: var(--input-background-color) !important;
    }

    &:disabled {
        background-color: var(--input-general-disabled-background-color);
        border-color: var(--input-general-disabled-border-color);
        color: var(--input-general-disabled-color);
        pointer-events: none;
    }
}

textarea {
    width: 100%;
    min-height: 100px;
    padding-top: 5px;
}

[type='text'] {
    @include mobile {
        width: 100%;
    }
}

input[autocomplete="current-password"] {
    font-family: $font-arial;
}
