/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.Slider {
    --translateX: 0;
    --translateY: 0;
    --animation-speed: 0;
    --slider-height: 550px !important;
    --slide-height: 0;
    --slide-width: 0;
    --height-transition-speed: 0;

    max-width: 100%;
    overflow: hidden;
    touch-action: pan-y;
    transition: height var(--height-transition-speed);

    &-Wrapper {
        display: flex;
        align-items: flex-start;
        height: 100%;
        will-change: transform;
        transition: transform var(--animation-speed);
        transform: translate3d(var(--translateX), var(--translateY), 0);

        > * {
            flex-grow: 1;
            flex-shrink: 0;
            width: 100%;
            height: 100%;
        }

        &_isVertical {
            flex-direction: column;
        }
    }

    &-Image {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-Counter {
        font-size: 1.2rem;
        color: var(--color-white);
        background: var(--color-dark-gray);
        border-radius: 15px;
        padding: 1px 4px 0;
        position: absolute;
        inset-block-end: 5px;
        inset-inline-end: 5px;
    }

    &-Crumbs {
        height: auto;
        min-height: 16px;
        position: absolute;
        margin: auto;
        grid-gap: 4px;
        width: 100%;
        grid-template-columns: repeat(auto-fit, 40px);
        justify-content: center;
        inset-block-end: 12px;

        @include mobile {
            display: none;
        }

        @include desktop {
            grid-gap: 10px;
            display: grid;
        }
    }

    &-Crumb {
        width: 40px;
        height: 7px;
        border-radius: 16px;
        background-color: var(--color-blue5);
        will-change: width, height;
        transition: width 300ms, height 300ms;
        cursor: pointer;

        &_isActive {
            background-color: var(--color-gray4);
        }
    }

    &-Arrow {
        cursor: pointer;
        position: absolute;
        inset-block-start: 50%;
        width: 40px;
        height: 40px;

        .ChevronIcon {
            width: 100%;
            height: 100%;
        }

        &_isPrev {
            left: -45px;

            @include arrow-left-icon;

            &::after {
                font-size: 24px;
                line-height: 24px;
            }
        }

        &_isNext {
            right: -45px;

            @include arrow-right-icon;

            &::after {
                font-size: 24px;
                line-height: 24px;
            }
        }

        &_isDisabled {
            pointer-events: none;

            &::after {
                color: var(--input-general-disabled-border-color);
            }
        }
    }
}
