/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --homepage-slider-color: #{$white};
}

.homepage-slider {
    h1 {
        margin-block-end: 30px;

        @include mobile {
            font-size: 30px;
            margin-block-end: 14px;
        }
    }

    .Slider-Crumbs {
        inset-block-end: 24px;

        @include mobile {
            inset-block-end: 28px;
        }
    }

    .SliderWidget-Figcaption {
        @include desktop {
            padding-block-start: 90px;
        }

        @include mobile {
            padding-inline: 16px;
            text-align: center;
        }
    }
}
