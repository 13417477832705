/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemanapwa-base
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * @link http://www.kemana.com
 *
 */

:root {
    --checkMark-inset-inline-start: calc(50% - 5px);
}

.Field {
    margin-top: 10px;

    &-Label {
        font-size: var(--label-general-font-size);
        font-weight: var(--label-general-font-weight);
        color: var(--label-general-text-color);
        line-height: calc(var(--label-general-line-height) - 4px);
        padding-block-end: 5px;

        &_isRequired {
            color: var(--label-general-required-text-color);
            line-height: normal;
            padding-bottom: 0;
        }
    }

    &-LabelContainer {
        display: flex;
        align-items: center;
        line-height: 1;

        &:not(.Field-LabelContainer_isRequired) {
            .Field-Label {
                @include optional-text;
            }
        }

        .ChevronIcon {
            width: 23px;
        }
    }

    &-CheckboxLabel, 
    &-RadioLabel {
        display: inline-flex;
        align-items: center;

        span {
            display: contents;
        }

        &:not([class*='_isDisabled']):hover {
            cursor: pointer;

            @include desktop {
                color: var(--primary-base-color);
            }
        }

        .input-control {
            @include mobile {
                min-width: 21px;
                min-height: 21px;
            }
        }
    }

    &-ErrorMessage {
        font-size: var(--base-secondary-font-size);
        color: var(--primary-error-color);
        text-align: start;
    }

    &-Message {
        color: var(--primary-error-color);
        font-size: var(--base-secondary-font-size);
        margin-block-end: -.1em;
        margin-block-start: 5px;
    }

    &-Note {
        margin-block-end: 2px;
        font-size: var(--base-secondary-small-font-size);
        font-weight: 600;
        color: var(--field-note);
        opacity: 0;
        height: 0;
        transition: height .1s ease-in, opacity .1s linear;
        will-change: height, opacity;

        @include mobile {
            margin-block-end: 3px;
            font-size: 14px;
        }

        &::before {
            content: '* ';
        }
    }

    &_hasError {
        input:not([type='number']),
        textarea,
        select {
            border: 1px solid var(--primary-error-color);
        }
    }

    input,
    textarea {
        &::-webkit-input-placeholder {
            font-family: var(--base-font-family);
            color: var(--input-placeholder-color);
        }
    }

    input:focus + .Field-Note {
        opacity: 1;
        height: 100%;
    }

    &_style {
        &_inline {
            $size-mobile: 20px;
            $size: 28px;
            $border-color: #000;
            $input-width-mobile: 35px;
            $input-width: 35px;

            &.Field {
                display: flex;
                margin: 0;

                button {
                    position: relative;
                    width: $size-mobile;
                    height: $size-mobile;
                    border: solid 1px $border-color;

                    @include desktop {
                        width: $size;
                        height: $size;
                    }

                    &:last-of-type {
                        order: -1;
                    }
                }
            }

            input {
                height: $size-mobile;
                min-height: $size-mobile;
                width: $input-width-mobile;
                min-width: $input-width-mobile;
                border: none;
                padding: 0;
                text-align: center;

                @include mobile {
                    font-size: 14px;
                }

                @include desktop {
                    height: $size;
                    min-height: $size;
                    width: $input-width;
                    min-width: $input-width;
                }

                &:focus {
                    border: none;
                }
            }
        }
    }

    &_type {
        &_checkbox,
        &_radio {
            span {
                font-size: var(--base-secondary-small-font-size);
            }

            label {
                &:first-of-type {
                    display: inline-flex;
                    vertical-align: middle;
                    max-width: 100%;
                }
            }

            input {
                width: 100%;
                height: 100%;
            }

            .input-control {
                vertical-align: middle;
                margin-inline-end: 11px;
            }
        }

        &_checkbox {
            label {
                &:first-of-type {
                    max-width: calc(100vw - 24px - 45px);

                    @include mobile {
                        max-width: 100%;
                    }
                }
            }
        }

        &_number_with_controls {
            align-items: center;
            display: inline-flex;

            .AddButton,
            .SubtractButton {
                width: 24px;
            }
        }

        &_textarea {
            line-height: 0;
        }

        &_file {
            &.Field {
                &_isValid {
                    input {
                        & + label {
                            word-break: break-word;
                            text-align: center;
                            border-color: var(--primary-success-color);
                        }
                    }
                }

                &_hasError {
                    input {
                        & + label {
                            word-break: break-word;
                            text-align: center;
                            border-color: var(--primary-error-color);
                        }
                    }
                }

                button, 
                .Field-SelectFileBtn {
                    cursor: pointer;
                    z-index: 2;

                    &:hover {
                        color: var(--button-hover-background);
                    }
                }
            }
        }
    }

    select {
        width: 100%;
    }

    [type='checkbox'],
    [type='radio'] {
        position: absolute;
        opacity: 0;
        padding: 0;
        min-width: 0;
        min-height: 0;
        z-index: 10;
        cursor: pointer;

        + .input-control {
            background-color: var(--general-background-color);
            border: 1px solid var(--checkbox-border-color);
            border-radius: var(--checkbox-border-radius);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            padding: 0;
            transition: var(--general-icon-animation);
            width: var(--checkbox-width);
            height: var(--checkbox-width);
            will-change: background-color, border-color;

            &::before,
            &::after {
                transition: var(--general-icon-animation);
                margin-left: 1px;
            }
        }

        &:disabled + .input-control {
            color: var(--input-general-disabled-color);
            cursor: auto;
        }

        &:hover + .input-control {
            cursor: pointer;

            @include desktop {
                --box-color: var(--checkbox-background-color-common-hover);

                border-color: var(--checkbox-border-hover-color);
            }
        }

        &:checked {
            + .input-control {
                border-color: var(--checkbox-border-color);
            }
        }
    }

    [type='checkbox'] {
        + .input-control {
            margin-right: 15px;
        }

        &:hover + .input-control {
            cursor: pointer;

            @include desktop {
                border-color: var(--checkbox-border-hover-color);

                @include filter-checked-icon;
            }
        }

        &:checked {
            + .input-control {
                border-color: var(--checkbox-border-color);

                @include filter-checked-icon;
            }
        }
    }

    [type='radio'] {
        + .input-control {
            border-radius: 50%;
            width: var(--radio-width);
            height: var(--radio-width);
            margin-right: 15px;

            &::after {
                content: '';
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                width: var(--radio-active-width);
                height: var(--radio-active-width);
                border-radius: 50%;
                transition-property: background-color, box-shadow;
                will-change: background-color, box-shadow;
            }
        }

        &:hover + .input-control {
            @include desktop {
                border-color: var(--radio-border-hover-color);

                &::after {
                    background: var(--radio-active-border-color);
                }
            }
        }

        &:checked {
            + .input-control {
                border-color: var(--radio-border-hover-color);

                &::after {
                    background: var(--radio-active-border-color);
                }
            }
        }
    }

    [type='file'] {
        border-style: dashed;
        cursor: pointer;
        height: 180px;
        opacity: 0;
        max-width: 100%;
        z-index: 1;

        + label {
            align-items: center;
            border: 2px dashed var(--input-border-color);
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 180px;
            position: absolute;
            inset-block-start: 0;
            width: 100%;

            p {
                font-size: 16px;
                margin-block-end: 0;
            }

            span, button {
                color: var(--primary-base-color);
                font-size: 18px;
                font-weight: 700;
                text-transform: var(--base-secondary-text-transform);
            }

            .UploadIcon {
                margin-block-end: 10px;
                height: 50px;
                width: auto;
            }

            + p, + button + p {
                margin-block-start: 5px;
                margin-block-end: 0;
            }
        }
    }

    &-Labels {
        span {
            letter-spacing: 1px;
        }
    }

    &-SubLabel {
        font-weight: bold;
        font-size: var(--base-secondary-small-font-size);
        white-space: break-spaces;
    }
}
