/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.NotificationList {
    position: fixed;
    z-index: 800;
    top: calc(var(--header-height) + var(--breadcrumbs-height) + 20px);
    right: 30px;
    max-width: 550px;
    list-style: none;

    @include desktop {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }

    @include mobile {
        overflow: hidden;
        max-width: 100vw;
        position: fixed;
        width: 100%;
        top: var(--header-total-height);
        right: 0;
    }
}
