/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.InstallPromptIOS {
    background: var(--homepage-top-categories-background-color);
    padding: 10px 45px 10px 10px;

    &-Heading {
        margin-bottom: 5px;
        text-align: left;
        width: 100%;
        font-size: var(--base-secondary-small-font-size);
        font-weight: $font-weight-bold;
    }

    &-Content {
        align-items: center;
        display: flex;
        justify-content: left;
        font-size: var(--base-secondary-small-font-size);
        width: 100%;
        margin-bottom: 0;
    }

    &-Close {
        position: absolute;
        z-index: 10;
        right: 10px;
        top: 15px;
        width: 25px;
        height: 26px;

        span {
            display: none;
        }
    }

    &-Plus {
        @include ios-plus;

        background-color: var(--general-icon-color);
        border-radius: 3px;
        margin: 0 4px;
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            font-size: 10px;
            line-height: 10px;
            margin: 0 0 0 1px;
        }
    }

    &-Share {
        @include ios-share;

        margin-left: 5px;

        &::before {
            font-size: var(--base-secondary-small-font-size);
            line-height: var(--base-secondary-small-font-size);
        }
    }

    p {
        line-height: 1;
    }
}
