/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.contacts-wrapper {
    dt,
    dd {
        display: inline-block;
        font-size: var(--base-secondary-small-font-size);

        @include mobile {
            font-size: 14px;
        }
    }

    dt {
        margin-inline-end: 12px;

        @include mobile {
            margin-inline-end: 14px;
        }
    }

    dd {
        margin-inline-end: 24px;

        @include mobile {
            margin-inline-end: 28px;
        }
    }
}
