/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --field-select-box-shadow: #{$box-shadow3};
    --field-select-background: var(--color-white);
    --field-select-border-radius: var(--general-border-radius);
    --field-select-option-height: 250px;
    --field-select-option-item-padding: 8px 8px 8px 15px;
    --field-select-option-item-font-size: var(--base-secondary-font-size);
    --field-select-option-item-color: var(--general-paragraph-color);
    --field-select-option-item-letter-spacing: #{$letter-spacing2};
    --field-select-option-item-hover-background: var(--header-top-menu-dropdown-hover-background);
}

$select-width: 160px !default;
$select-height: 45px !default;
$select-option-height: 36px !default;
$select-arrow-height: 14px !default;
$select-arrow-width: 14px !default;

.FieldSelect {
    &[aria-expanded=true] {
        &::before {
            content: "";
            position: absolute;
            height: 3px;
            background-color: var(--field-select-background);
            border-bottom: 1px solid var(--input-border-color);
            top: 40px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 233;
            width: 91%;
        }
    }

    &-Clickable {
        cursor: pointer;
        display: flex;
        align-items: center;

        @include top-down-icon;

        &::after {
            position: absolute;
            right: 15px;
        }
    }

    .ChevronIcon {
        display: none;
    }

    &_isExpanded {
        .FieldSelect-Clickable {
            @include top-up-icon;
        }
    }

    &-Select {
        width: 100%;
        padding-inline-end: 40px;
        min-height: 30px;
        height: max-content;

        @include desktop {
            pointer-events: none;
        }

        &_isDisabled {
            opacity: 0.6;
        }
    }

    &-Options {
        position: absolute;
        width: 100%;
        top: 92%;
        left: 0;
        z-index: 40;
        border: 1px solid transparent;
        overflow-y: auto;
        transition: max-height .2s;
        will-change: max-height;
        display: none;

        &Wrapper {
            @include desktop {
                display: none;

                &_isExpanded {
                    width: 100%;
                    display: block;
                    overflow-y: auto;
                    max-height: var(--field-select-option-height);
                }
            }
        }

        @include mobile {
            height: 0;
        }

        &_isExpanded {
            display: flex;

            @include desktop {
                max-height: 168px;
                z-index: 15;
                background-color: var(--field-select-background);
                border-radius: var(--field-select-border-radius);
                border-color: var(--input-border-color);
                border-top: 0;
                padding-top: 3px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        &_isUpDirection {
            inset-block-start: auto;
            inset-block-end: 100%;
            border-width: 1px 1px 0;
        }

        &_isNotScrollable {
            overflow-y: hidden;

            // Firefox support
            /* stylelint-disable-next-line declaration-no-important */
            scrollbar-width: none !important;
            /* stylelint-disable-next-line declaration-no-important */
            scrollbar-color: none !important;

            &::-webkit-scrollbar {
                display: none;
            }

            &::-webkit-scrollbar-thumb {
                display: none;
            }
        }
    }

    &-Option {
        cursor: pointer;
        margin: 0;
        z-index: 1;
        will-change: max-height;
        padding: var(--field-select-option-item-padding);
        line-height: 16px;
        background-color: var(--field-select-background);
        font-size: var(--field-select-option-item-font-size);
        letter-spacing: var(--field-select-option-item-letter-spacing);
        color: var(--field-select-option-item-color);

        @include mobile {
            line-height: 42px;
            padding-inline-start: 21px;
        }

        &_isPlaceholder {
            opacity: 0.6;
        }

        &::before {
            content: none;
        }

        &:last-of-type {
            margin: 0;
        }

        &:hover,
        &:focus {
            @include desktop {
                background: var(--field-select-option-item-hover-background);
            }
        }

        &_isHovered {
            @include desktop {
                background: var(--field-select-option-item-hover-background);
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: 0.6;

            @include mobile {
                border-top: 1px solid var(--input-border-color-focus);
            }
        }
    }
}
