/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

fieldset {
    border: 0;
}

legend {
    font-size: 16px;
    padding-block-end: 6px;
    display: block;
    width: 100%;

    @include mobile {
        font-size: 18px;
        padding-block-end: 7px;
    }
}
