/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --image-default-background: var(--secondary-base-color);
    --image-content-color: var(--color-black);
}

.Image {
    display: inline-block;
    overflow: hidden;
    height: 0;
    width: 100%;
    
    &_ratio {
        &_16x9 {
            padding-block-end: 56.25%;
        }

        &_4x3 {
            padding-block-end: 75%;
        }

        &_square {
            padding-block-end: 100%;
        }
    }

    &_imageStatus_image_loading,
    &_isPlaceholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
    }

    &_hasSrc,
    &_imageStatus_image_loaded {
        background: none;
    }

    &-WidthFull {
        width: 100%;
        border-radius: var(--general-image-border-radius);

        td & {
            max-width: 100%;
            display: block;
            margin: auto;
        }
    }

    &-HeightFull {
        height: 100%;
        border-radius: var(--general-image-border-radius);

        td & {
            height: auto;
            display: block;
            margin: auto;
        }
    }

    &-Content {
        position: absolute;
        height: max-content;
        width: 100%;
        text-align: center;
        margin: auto;
        inset-block-end: 0;
        inset-block-start: 0;
        inset-inline-start: 0;
        color: var(--image-content-color);
        font-size: 14px;

        &_isOffline {
            width: 100%;
            height: 100%;
            background: var(--image-default-background);
        }
    }

    &-Loader {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
    }

    &-Image {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        object-position: 50% 50%;
        object-fit: contain;
        color: transparent;
        border-radius: var(--general-image-border-radius);

        &::after {
            content: '';
            position: absolute;
            z-index: -1;
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 100%;
            background: var(--image-default-background);
        }
    }
}
