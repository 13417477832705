/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    // Colors
    --color-black: #{$black};
    --color-white: #{$white};
    --color-gray1: #{$grey1};
    --color-gray2: #{$grey2};
    --color-gray3: #{$grey3};
    --color-gray4: #{$grey4};
    --color-gray5: #{$grey5};
    --color-gray6: #{$grey6};
    --color-gray7: #{$grey7};
    --color-gray8: #{$grey8};
    --color-gray9: #{$grey9};
    --color-gray10: #{$grey10};
    --color-gray11: #{$grey11};
    --color-gray12: #{$grey12};
    --color-gray13: #{$grey13};
    --color-gray14: #{$grey14};
    --color-blue1: #{$blue1};
    --color-blue2: #{$blue2};
    --color-blue3: #{$blue3};
    --color-blue4: #{$blue4};
    --color-blue5: #{$blue5};
    --color-red1: #{$red1};
    --color-red2: #{$red2};
    --color-green1: #{$green1};
    --color-green2: #{$green2};

    // General
    --layout-max-width: 1300px;
    --layout-mobile-vertical-padding: 11px;

    @include tablet {
        --layout-mobile-vertical-padding: 40px;
    }
    
    --base-font-family: #{$base-font-family};
    --base-primary-font-size: 17px;
    --base-secondary-font-size: 15px;
    --base-secondary-small-font-size: 12px;
    --base-secondary-text-transform: uppercase;
    --general-subtitle-color: var(--color-gray3);
    --general-subtitle-secondry-color: var(--color-gray1);
    --general-paragraph-color: var(--secondary-light-color);
    --general-link-color: var(--primary-light-color);
    --general-icon-color: var(--color-black);
    --general-icon-hover-color: var(--primary-light-color);
    --general-icon-animation: #{$transition1};
    --general-image-border-radius: 10px;
    --general-small-image-border-radius: 5px;
    --general-image-background-color: var(--color-gray5);
    --general-image-hover-opacity: .4;
    --general-background-color: var(--color-white);
    --general-border-radius: 5px;
    --general-container-background-color: var(--color-gray5);
    --general-container-padding: 20px 20px 25px;

    // Field
    --input-general-password-font-family: #{$font-muli};
    --input-general-height: 42px;
    --input-general-letter-spacing: 0;
    --input-general-background-color: var(--color-white);
    --input-general-border-color: var(--color-gray1);
    --input-general-border-color-focus: var(--primary-light-color);
    --input-general-shadow-color-focus: #{$box-shadow2};
    --input-general-text-color: var(--color-gray7);
    --input-general-placeholder-color: var(--input-general-border-color);
    --input-general-disabled-border-color: var(--color-gray8);
    --input-general-disabled-background-color: var(--color-gray5);
    --input-general-disabled-color: var(--color-gray1);

    --checkbox-width: 24px;
    --checkbox-icon-color: var(--primary-light-color);
    --checkbox-border-color: var(--input-general-border-color);
    --checkbox-border-hover-color: var(--input-general-border-color-focus);
    --checkbox-border-radius: 0;

    --radio-width: 24px;
    --radio-active-width: 12px;
    --radio-icon-color: var(--primary-light-color);
    --radio-border-color: var(--input-general-border-color);
    --radio-border-hover-color: var(--input-general-border-color);
    --radio-active-border-color: var(--input-general-border-color-focus);

    --required-text-color: var(--primary-error-color);

    --review-star-large-font-size: 30px;
    --review-star-disabled-color: var(--color-gray14);
    --review-star-active-color: var(--primary-base-color);

    // SearchField
    --search-general-height: 36px;
    --search-general-font-size: var(--base-secondary-font-size);
    --search-general-letter-spacing: #{$letter-spacing2};
    --search-general-background-color: var(--color-gray5);

    // Label
    --label-general-font-size: 17px;
    --label-general-line-height: 28px;
    --label-general-font-weight: #{$font-weight-medium};
    --label-general-text-color: var(--primary-dark-color);
    --label-general-required-text-color: var(--primary-error-color);

    // Notifications
    --notification-icon-size: 18px;
    --primary-error-color: var(--color-red1);
    --primary-success-color: var(--color-green1);
    --primary-info-color: var(--primary-base-color);
    --secondary-error-color: var(--color-red2);
    --secondary-success-color: var(--color-green2);
    --secondary-info-color: var(--color-blue2);

    // Dividers
    --primary-divider-color: var(--color-gray8);
    --secondary-divider-color: var(--primary-dark-color);

    // Header
    --header-logo-desktop-width: 210px;
    --header-logo-desktop-height: 50px;
    --header-logo-mobile-width: 130px;
    --header-logo-mobile-height: 28px;
    --header-box-shadow: #{$box-shadow3};
    --header-top-menu-font-size: 13px;
    --header-top-menu-text-decoration: underline;
    --header-top-menu-font-weight: #{$font-weight-medium};
    --header-top-menu-color: var(--color-gray3);
    --header-top-menu-icon-size: 5px;
    --header-top-menu-dropdown-hover-background: var(--color-gray5);
    --header-icons-width: 25px;

    // Menu
    --menu-desktop-width: 850px;
    --menu-mobile-background: var(--color-gray5);
    --menu-mobile-other-links-color: var(--color-gray7);
    --menu-parent-item-font-size: var(--base-secondary-font-size);
    --menu-parent-item-font-weight: #{$font-weight-medium};
    --menu-parent-item-padding-bottom: 10px;
    --menu-parent-item-color: var(--primary-dark-color);
    --menu-parent-item-border-bottom-width: 5px;
    --menu-parent-item-border-bottom-color: transparent;
    --menu-parent-item-border-bottom-active-color: var(--secondary-base-color);
    --menu-parent-item-text-transform: none;
    --menu-sub-height: 540px;
    --menu-sub-item-background-color: var(--color-white);
    --menu-sub-item-right-arrow-color: var(--primary-dark-color);
    --menu-sub-item-box-shadow: #{$box-shadow4};
    --menu-sub-item-parent-font-size: 17px;
    --menu-sub-item-parent-font-weight: #{$font-weight-semibold};
    --menu-sub-item-parent-color: var(--menu-parent-item-color);
    --menu-sub-item-sub-font-size: var(--base-secondary-font-size);
    --menu-sub-item-sub-color: var(--general-paragraph-color);
    --menu-mobile-tab-container-box-shadow: #{$box-shadow5};
    --menu-mobile-tab-icon-color: var(--secondary-light-color);
    --menu-mobile-tab-icon-hover-color: var(--general-link-color);
    --menu-mobile-tab-home-icon-size: 24px;
    --menu-mobile-tab-menu-icon-size: 22px;
    --menu-mobile-tab-minicart-icon-size: 24px;
    --menu-mobile-tab-user-icon-size: 24px;

    // Footer
    --footer-max-width: 1080px;
    --footer-background-color: var(--color-black);
    --footer-text-primary-color: var(--color-blue1);
    --footer-text-secondary-color: var(--color-white);
    --footer-title-font-size: 13px;
    --footer-title-font-weight: #{$font-weight-medium};
    --footer-description-font-size: 13px;
    --footer-promo-background-color: var(--color-blue4);
    --footer-promo-title-color: var(--color-white);
    --footer-promo-description-color: var(--color-blue1);
    --footer-promo-link-size: var(--secondary-base-color);
    --footer-copyright-font-size: 11px;
    --footer-copyright-text-transform: var(--base-secondary-text-transform);
    --footer-copyright-letter-spacing: #{$letter-spacing3};

    // Cart
    --cart-count-font-size: 13px;
    --cart-count-font-weight: #{$font-weight-medium};
    --cart-count-color: var(--color-white);
    --cart-count-background-color: var(--color-red1);
    --cart-options-font-size: 13px;
    --cart-options-font-style: italic;
    --cart-options-color: var(--color-gray7);
    --cart-price-font-size: var(--base-secondary-font-size);
    --cart-price-font-weight: #{$font-weight-bold};
    --cart-price-color: var(--primary-base-color);

    // Qty Selector
    --qty-selector-width: 20px;
    --qty-selector-background-color: var(--primary-light-color);
    --qty-selector-hover-background-color: var(--secondary-dark-color);
    --qty-selector-color: var(--color-white);
    --qty-selector-disabled-background-color: var(--color-gray9);
    --qty-selector-disabled-icon-color: var(--input-general-border-color);

    // Product
    --product-detail-page-top-max-width: 1000px;
    --product-detail-page-gallery-width: 490px;
    --product-detail-page-gallery-thumb-width: 70px;
    --product-action-icon-width: 29px;
    --product-action-icon-border-radius: 50%;
    --product-action-icon-color: var(--color-gray13);
    --product-action-icon-active-color: var(--primary-light-color);
    --product-price-badge-font-size: 13px;
    --product-price-badge-color: var(--color-gray3);
    --product-brand-font-size: 10px;
    --product-brand-letter-spacing: #{$letter-spacing6};

    // Checkout
    --order-summary-block-width: 420px;
    --order-summary-background-color: var(--general-container-background-color);
    --order-summary-heading-background-color: var(--color-gray9);
    --order-summary-coupon-code-color: var(--color-black);
    --checkout-background-color: var(--general-container-background-color);
    --checkout-header-max-width: 1080px;
    --checkout-header-desktop-height: 65px;
    --checkout-header-mobile-height: 50px;
    --checkout-address-list-border-color: var(--primary-divider-color);
    --checkout-address-list-selected-border-color: var(--primary-light-color);

    // My Account
    --my-account-background: var(--general-container-background-color);
    --my-account-table-row-hover-background-color: var(--color-gray9);
    --my-account-table-column-parent-font-weight: #{$font-weight-medium};
    --my-account-tablist-mobile-shadow: #{$box-shadow8};
    --my-account-order-view-tabs-background: var(--color-gray9);

    //CMS Pages
    --cms-center-content-max-width: 810px;
    --cms-accodian-detail-font-weight: #{$font-weight-medium};

    // Declare imported primary colors to change the theme's colors
    // "imported_..." variables are set up in Admin panel -> Stores -> Configuration -> Kemanapwa -> Color Configuration
    // They have higher priority than "default_..." variables defined in code.
    --primary-base-color: var(--imported_primary_base_color, #{$default-primary-base-color});
    --primary-dark-color: var(--imported_primary_dark_color, #{$default-primary-dark-color});
    --primary-light-color: var(--imported_primary_light_color, #{$default-primary-light-color});
    --secondary-base-color: var(--imported_secondary_base_color, #{$default-secondary-base-color});
    --secondary-dark-color: var(--imported_secondary_dark_color, #{$default-secondary-dark-color});
    --secondary-light-color: var(--imported_secondary_light_color, #{$default-secondary-light-color});
}
