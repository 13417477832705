/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */
 
:root {
    --menu-item-height: 20px;
    --menu-total-height: 53px;
}

@mixin subcategory-visible {
    pointer-events: all;
    z-index: 10;
    opacity: 1;
    transform: translateX(0);
}

.Menu {
    &-MenuWrapper {
        @include desktop {
            padding-left: var(--layout-mobile-vertical-padding);
            padding-right: var(--layout-mobile-vertical-padding);
            margin-bottom: -10px;
            width: 100%;
            max-width: var(--menu-desktop-width);
        }

        @include desktop-xl {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-CompareLink {
        color: var(--color-black);
        font-size: var(--base-secondary-font-size);
        font-weight: $font-weight-regular;
        text-transform: none;
        display: flex;
        align-items: center;

        .CompareIcon {
            margin-inline-end: 12px;

            @include mobile {
                display: none;
            }

            &:hover {
                fill: var(--color-black);
            }

            + span {
                @include mobile {
                    display: none;
                }
            }
        }

        &Wrapper {
            border-block-end: 1px solid var(--primary-divider-color);
            padding-block-start: 17px;
            padding-block-end: 16px;
            padding-inline: 0;
            margin: 0 16px;

            @include mobile {
                width: 100%;
                order: 2;
                margin-left: var(--layout-mobile-vertical-padding);
                margin-right: var(--layout-mobile-vertical-padding);
                padding-block: var(--layout-mobile-vertical-padding);
            }
        }

        h4 {
            @include mobile {
                color: var(--menu-mobile-other-links-color);
                font-size: var(--menu-sub-item-sub-font-size);
                font-weight: $font-weight-regular;
                line-height: 19px;
            }
        }
    }

    &-CompareCountInMenu {
        color: var(--menu-mobile-other-links-color);
        font-size: var(--menu-sub-item-sub-font-size);
        font-weight: $font-weight-regular;
        line-height: 16px;
        margin-left: 5px;
    }

    &-ExpandedState {
        --plus-minus-icon-size: 18px;

        display: inline-block;
        float: inline-end;

        &:dir(rtl) {
            float: inline-start;
        }

        position: absolute;
        inset-inline-end: 0;

        @include desktop {
            display: none;
        }
    }

    &-Item {
        padding-inline-start: 0;

        @include desktop {
            height: var(--menu-item-height);
            margin: 0;
        }

        &:last-of-type {
            @include mobile {
                border-bottom: 1px solid var(--primary-divider-color);
                margin-bottom: 0;
            }

            @include desktop {
                margin-bottom: 0;
            }

            > .Menu-SubCatLink,
            > .Menu-Link {
                .Menu-ItemCaption_type_main {
                    @include mobile {
                        border-bottom: 0;
                    }
                }
            }
        }

        &::before {
            content: none;
        }

        &Caption {
            color: var(--menu-parent-item-color);

            &:hover {
                color: var(--link-hover);
            }

            @include mobile {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid var(--primary-divider-color);
                margin-left: var(--layout-mobile-vertical-padding);
                margin-right: var(--layout-mobile-vertical-padding);
                padding-block: var(--layout-mobile-vertical-padding);
                cursor: pointer;
            }

            &_isSecondLevel {
                font-weight: 700;
            }

            &_type {
                &_main {
                    font-size: var(--menu-parent-item-font-size);
                    font-weight: var(--menu-parent-item-font-weight);
                    text-transform: var(--menu-parent-item-text-transform);
                    line-height: 20px;

                    @include desktop {
                        padding-bottom: calc(var(--menu-parent-item-padding-bottom) - var(--menu-parent-item-border-bottom-width));
                        border-bottom-width: var(--menu-parent-item-border-bottom-width);
                        border-bottom-color: var(--menu-parent-item-border-bottom-color);
                        border-bottom-style: solid;
                    }
                }

                &_subcategory {
                    line-height: 20px;

                    @include mobile {
                        font-size: var(--menu-parent-item-font-size);
                        font-weight: var(--menu-parent-item-font-weight);
                        text-transform: var(--menu-parent-item-text-transform);
                    }

                    @include desktop {
                        display: inline-block;
                    }
                }
            }
        }

        &Figure {
            &_type_hideOnDesktop {
                @include desktop {
                    display: none;
                }
            }
        }

        &List {
            @include mobile {
                width: 100%;
                order: 1;
                background-color: var(--menu-sub-item-background-color);
                border-top: 1px solid var(--primary-divider-color);
            }

            @include desktop {
                display: flex;
                width: 100%;

                .Menu-SubMenu & {
                    display: block;
                }
            }
        }

        &:not(:first-of-type) {
            @include desktop {
                margin-left: 20px;
            }
        }
    }

    &-SubCatLink,
    &-Link {
        .Menu-ItemCaption {
            @include mobile {
                @include arrow-right-icon;

                .AddIcon {
                    display: none
                }
            }
        }
    }

    &-Link {
        display: contents;
        font-weight: 400;
        margin-block: 8px;
        margin-inline: 0 16px;

        .Menu-ItemCaption {
            @include mobile {
                &::after {
                    display: none;
                }
            }
        }
    }

    &-MainCategories {
        margin: 0 auto;

        @include mobile {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }

        @include desktop {
            padding-bottom: var(--menu-parent-item-padding-bottom);
            display: flex;
            justify-content: center;
        }

        .Menu-Link {
            &_isHovered {
                .Menu-ItemCaption_type_main {
                    @include desktop {
                        border-bottom-color: var(--menu-parent-item-border-bottom-active-color);
                    }
                }
            }
        }
    }

    &-SubCategories {
        @include desktop {
            padding-left: 40px;
            padding-right: 20px;
            padding-bottom: 30px;
            padding-top: 30px;
        }

        &Wrapper {
            &_isVisible {
                @include desktop {
                    background-color: var(--menu-sub-item-background-color);
                    height: var(--menu-sub-height);
                    position: fixed;
                    z-index: 9;
                    padding-top: 9px;
                    max-width: var(--layout-max-width);
                    width: 100%;

                    &::before {
                        background-color: #{$overlay1};
                        content: '';
                        width: 100%;
                        height: 100vh;
                        display: flex;
                        z-index: -1;
                        position: fixed;
                        left: 0;
                        top: var(--header-total-height);
                    }
                }
            }

            &Inner {
                &_isVisible {
                    @include desktop {
                        background-color: var(--menu-sub-item-background-color);

                        &::before {
                            box-shadow: var(--menu-sub-item-box-shadow);
                            content: '';
                            position: absolute;
                            top: 0;
                            width: 100%;
                            height: 25px;
                        }
                    }
                }
            }
        }
    }

    &-Sub {
        &ItemWrapper {
            break-inside: avoid;
            overflow: hidden;

            @include desktop {
                padding-bottom: 15px;
                padding-right: 20px;
            }

            > .Menu-Link {
                @include desktop {
                    font-size: var(--menu-sub-item-parent-font-size);
                    font-weight: var(--menu-sub-item-parent-font-weight);

                    .Menu-ItemCaption {
                        color: var(--menu-sub-item-parent-color);
                    }
                }
            }
        }

        &Menu {
            display: none;

            @include mobile {
                opacity: 0;
                pointer-events: none;
                touch-action: pan-y;
                transform: translateX(100%);
                transition: transform .2s ease-in-out;
                height: 100%;
                left: 0;
                padding-top: var(--header-total-height);
                padding-bottom: var(--footer-nav-height);
                position: fixed;
                top: 0;
                width: 100%;
                background: var(--menu-sub-item-background-color);
                overflow-y: hidden;
            }

            &_isVisible {
                background: var(--color-gray);
                display: block;
                padding-inline: 16px;

                @include mobile {
                    pointer-events: all;
                    z-index: 10;
                    opacity: 1;
                    transform: translateX(0);
                    padding-inline: 0;
                    margin-block-end: 0;
                    background: var(--menu-sub-item-background-color);
                }

                @include desktop {
                    height: var(--menu-sub-height);
                    overflow-y: auto;
                }

                .Menu-Link {
                    &:hover {
                        @include desktop {
                            .Menu-ItemCaption {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            @include desktop {
                background: none;
                display: block;
                padding: 0;
            }

            .Menu-SubMenu & {
                padding-block-end: 0;
            }

            .Menu-ItemCaption {
                @include desktop {
                    padding-block-end: 15px;
                }
            }

            > .Menu-ItemList {
                @include desktop {
                    columns: 5;
                }

                .Menu-ItemList {
                    .Menu-Link {
                        @include desktop {
                            font-size: var(--menu-sub-item-sub-font-size);
                            color: var(--menu-sub-item-sub-color);
                        }

                        .Menu-ItemCaption {
                            @include desktop {
                                font-size: var(--menu-sub-item-sub-font-size);
                                color: var(--menu-sub-item-sub-color);
                            }
                        }
                    }
                }
            }
        }
    }
}
