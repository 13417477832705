/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

blockquote {
    line-height: 1.6;
    margin-block-end: 12px;
    padding-block-start: 6px;
    padding-inline-start: 14px;
    border-inline-start: 1px solid var(--secondary-dark-color);
    color: var(--secondary-dark-color);
    font-size: var(--base-secondary-small-font-size);

    @include mobile {
        margin-block-end: 14px;
        padding-block-start: 7px;
        padding-inline: 17px 17px;
        font-size: 14px;
    }
}
