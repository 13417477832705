/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */
 
@mixin button-disabled {
    pointer-events: none;
    opacity: .25;
}

@mixin button {
    text-decoration: var(--button-text-decoration);
    background-color: var(--button-background);
    border-radius: var(--button-border-radius);
    color: var(--button-color);
    font-family: var(--default-button-font-family);
    font-weight: var(--default-button-font-weight);
    font-size: var(--default-button-font-size);
    height: var(--button-height);
    line-height: var(--button-height);
    text-transform: var(--default-button-text-transform);
    padding: var(--button-padding);
    letter-spacing: var(--default-button-letter-spacing);
    border-width: var(--button-border-width);
    border-color: var(--button-border);
    border-style: solid;
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    transition-duration: .25s;
    transition-timing-function: ease-out;
    transition-property: background-color, color, border;
    will-change: background-color, color, border;

    @include mobile {
        user-select: none;
        width: 100%;
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:not([disabled]) {
        &:hover {
            @include desktop {
                background-color: var(--button-hover-background);
                border-color: var(--button-hover-border);
                color: var(--button-hover-color);
            }
        }
    }

    &[disabled],
    &.disabled {
        @include button-disabled();

        &::before {
            color: var(--color-gray1);
        }
    }

    &_isHollow {
        background-color: var(--hollow-button-background);
        border-color: var(--hollow-button-border);
        color: var(--hollow-button-color);

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    background-color: var(--hollow-button-hover-background);
                    border-color: var(--hollow-button-hover-border);
                    color: var(--hollow-button-hover-color);
                }
            }
        }
    }

    &_Secondary {
        background-color: var(--secondary-button-background);
        border-color: var(--secondary-button-border);
        color: var(--secondary-button-color);

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    background-color: var(--secondary-button-hover-background);
                    border-color: var(--secondary-button-hover-border);
                    color: var(--secondary-button-hover-color);
                }
            }
        }
    }

    &_SecondaryRevert {
        background-color: var(--secondary-revert-button-background);
        border-color: var(--secondary-revert-button-border);
        color: var(--secondary-revert-button-color);

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    background-color: var(--secondary-revert-button-hover-background);
                    border-color: var(--secondary-revert-button-hover-border);
                    color: var(--secondary-revert-button-hover-color);
                }
            }
        }
    }

    &_isWithoutBorder {
        border: 0;
    }

    &_isSmallButton {
        height: var(--button-small-height);
        line-height: var(--button-small-height);
        padding: var(--button-small-padding);
    }

    &_likeLink {
        --default-button-font-size: var(--button-like-link-font-size);
        --button-border: transparent;
        --button-background: transparent;
        --button-color: var(--button-like-link-color);
        --button-hover-border: transparent;
        --button-hover-background: transparent;
        --button-hover-color: var(--button-like-link-hover-color);
        --button-padding: var(--button-like-link-padding);
        --default-button-letter-spacing: var(--button-like-link-letter-spacing);
        --button-height: min-content;
        --button-hover-height: min-content;
        --default-button-text-transform: none;
        --default-button-font-weight: var(--button-like-link-font-weight);

        border: none;
        margin: 0;
        padding: 0;
        text-align: start;
        line-height: 24px;
        width: auto;

        &:hover,
        &:focus {
            padding: 0;
        }

        &:hover {
            @include desktop {
                text-decoration: var(--button-like-link-hover-text-decoration);
            }
        }
    }

    &_likeLink2 {
        --default-button-font-size: var(--button-like-link2-font-size);
        --button-border: transparent;
        --button-background: transparent;
        --button-color: var(--button-like-link2-color);
        --button-hover-border: transparent;
        --button-hover-background: transparent;
        --button-hover-color: var(--button-like-link2-hover-color);
        --button-padding: var(--button-like-link2-padding);
        --default-button-letter-spacing: var(--button-like-link2-letter-spacing);
        --button-height: min-content;
        --button-hover-height: min-content;
        
        --default-button-font-weight: var(--button-like-link2-font-weight);

        border: none;
        margin: 0;
        padding: 0;
        text-align: start;
        line-height: 24px;
        width: auto;

        &:hover,
        &:focus {
            padding: 0;
        }

        &:hover {
            @include desktop {
                text-decoration: var(--button-like-link2-hover-text-decoration);
            }
        }
    }

    &_LinkWithBorder {
        flex-wrap: wrap;

        &::after {
            content: '';
            background-color: var(--primary-light-color);
            height: 1px;
            display: flex;
            width: 100%;
        }

        &:hover {
            @include desktop {
                &::after {
                    background-color: var(--button-like-link2-hover-color);
                }
            }
        }
    }
}

@mixin button-link {
    --default-button-font-size: 15px;
    --default-button-letter-spacing: 0;
    --default-button-font-weight: #{$font-weight-medium};

    color: var(--hollow-button-color);

    &:hover {
        @include desktop {
            text-decoration: underline;
        }
    }
}
