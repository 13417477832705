/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.CarouselScroll {
    --translateY: 0;
    --animation-speed: 300ms;

    height: 100%;
    display: flex;
    align-items: center;
    width: fit-content;

    &-ContentWrapper {
        overflow: hidden;
        flex: 1;
        display: flex;
        justify-content: center;
    }

    &-Content {
        transform: translateX(var(--translateX));
        transition: transform var(--animation-speed);
        padding-inline-end: 1px;
        display: flex;
        flex-direction: row;
    }
}
