/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.CarouselScrollItem {
    display: flex;
    scroll-snap-align: start;
    border: solid 2px transparent;
    transition: border-color var(--animation-speed), filter var(--animation-speed);
    filter: opacity(.5);
    flex: 1;
    cursor: pointer;
    width: var(--carousel-scroll-item-height);
    height: var(--carousel-scroll-item-height);
    border-radius: var(--general-image-border-radius);

    & + .CarouselScrollItem {
        margin-left: 20px;
    }

    &_isActive {
        filter: opacity(1);
        border-color: var(--primary-light-color);
    }

    &:focus,
    &:hover {
        filter: opacity(1);
    }
}
