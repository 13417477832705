/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --search-bar-max-width: 210px;
    --search-bar-width: 270px;
    --search-clear-background: var(--secondary-base-color);
    --search-field-background: var(--secondary-base-color);
    --search-field-border-color: var(--primary-divider-color);
    --search-field-color: var(--secondary-base-color);
    --search-field-visible-background: var(--color-white);
    --search-input-desktop-border-color: var(--color-black);
}

::placeholder {
    overflow: visible;
}

.SearchField {
    display: none;
    margin: 8px auto;
    max-width: 100%;
    width: 100%;

    @include desktop {
        display: flex;
        order: 3;
        margin: 0;
        justify-content: flex-end;
        padding-right: 30px;
    }

    @include desktop-xxl {
        width: calc(100% - var(--menu-desktop-width));
    }

    &_isVisible {
        display: flex;
    }

    &_isActive {
        .SearchOverlay {
            &-Results {
                display: block;
            }
        }
    }

    &-Wrapper {
        @include mobile {
            width: 100%;
        }

        @include desktop {
            max-width: var(--search-bar-max-width);
        }
    }

    &-CloseIcon,
    &-SearchIcon {
        position: absolute;
        inset-block-start: calc(50% - 12px);
        right: 15px;

        .SearchIcon,
        .CloseIcon {
            display: none;
        }
    }

    &-SearchIcon {
        pointer-events: none;

        @include search-icon;
    }

    &-CloseIcon {
        @include close-icon;

        cursor: pointer;
    }

    &-Input {
        --input-background-color: var(--search-general-background-color);
        --input-border-color: var(--search-general-background-color);
        --input-height: var(--search-general-height);
        --input-letter-spacing: var(--search-general-letter-spacing);
        --base-primary-font-size: var(--search-general-font-size);

        width: 100%;

        &:focus {
            --input-border-color-focus: var(--search-general-background-color);

            box-shadow: none;
        }
    }
}
