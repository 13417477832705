/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ReviewStar {
    --star-outline-color: transparent;

    // reset from input default styles
    display: inline;
    margin: 0;
    padding: 0;
    background: none;
    border: 0;
    transition: color 150ms ease-in;
    color: var(--review-star-disabled-color);
    text-shadow: -1px 0 var(--star-outline-color),
        0 1px var(--star-outline-color),
        1px 0 var(--star-outline-color),
        0 -1px var(--star-outline-color);
    font-size: var(--review-star-large-font-size);
    line-height: 1;
    height: unset;
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: Times; // make sure ★ appears correctly
    letter-spacing: 3px;

    @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        height: var(--review-star-large-font-size);
    }

    &::after {
        content: '\e939';
        font-family: $icomoon-font-family;
        letter-spacing: 5px;
    }

    &[id*='=='] {
        margin-block-start: 0;
    }

    &:checked,
    &:hover,
    &[checked],
    &:checked ~ input,
    &:hover ~ input,
    &[checked] ~ input {
        --star-outline-color: transparent;

        color: var(--review-star-active-color);
    }

    &:focus {
        border: 0;
    }
}
