/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.ExpandableContentShowMore {
    text-align: left;

    &-ShowAllButton {
        @include top-down-icon;

        &::after {
            color: var(--primary-light-color);
            margin-left: 10px;
        }

        &_state_isOpen {
            @include top-up-icon;

            &::after {
                color: var(--primary-light-color);
            }
        }

        &.Button_likeLink {
            --default-button-text-transform: none;
            --button-height: fit-content;
            --button-hover-height: fit-content;
            --button-hover-padding: 0;
    
            margin-top: $indent-space1;
    
            @include h5;
    
            &:focus:not(:hover) {
                text-decoration: unset;
            }
    
            &:hover {
                span {
                    @include desktop {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &-ExpandableChildren {
        transition: .5s;
        overflow: hidden;
    }
}
