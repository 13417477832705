/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --store-switcher-list-width: 106px;
} 

.StoreSwitcher {
    border-block-end: 1px solid var(--primary-divider-color);

    @include desktop {
        border-block-end: none;
        margin-inline-start: 24px;
    }

    @include mobile {
        width: 100%;
        order: 4;
        margin-left: var(--layout-mobile-vertical-padding);
        margin-right: var(--layout-mobile-vertical-padding);
        padding-block: var(--layout-mobile-vertical-padding);
    }

    .Field {
        margin-block-start: 0;

        &Select {
            &-Select {
                border: none;
                font-size: 14px;
                font-weight: 400;
                opacity: 1;
                padding-inline-start: 0;
                line-height: 20px;

                @include mobile {
                    background-color: transparent;
                }

                &:focus {
                    @include mobile {
                        box-shadow: none;
                    }
                }
            }

            &::after {
                height: 13px;
                inset-inline-end: 0;
                width: 13px;
            }
        }
    }

    .ChevronIcon {
        width: 14px;
        height: 14px;
        margin-inline-start: 10px;
        inset-inline-end: 0;
        display: none;
    }

    &-Title {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: var(--header-top-menu-font-size);
        font-weight: var(--header-top-menu-font-weight);
        color: var(--header-top-menu-color);
        padding-right: 12px;

        @include top-down-icon;

        &::after {
            position: absolute;
            right: 0;
            top: 4px;
            font-size: var(--header-top-menu-icon-size);
        }

        &_isOpen {
            @include top-up-icon;

            text-decoration: underline;

            &::after {
                font-size: var(--header-top-menu-icon-size);
            }
        }
    }

    &-StoreList {
        display: none;
        inset-inline-end: -20px;
        position: absolute;
        inset-block-start: 20px;
        z-index: 2;

        @include desktop {
            box-shadow: var(--field-select-box-shadow);
            background-color: var(--field-select-background);
            border-radius: var(--field-select-border-radius);
            min-width: var(--store-switcher-list-width);
        }

        &_isOpen {
            display: block;
            right: 0 !important;

            @include dropdown-up-arrow-icon;

            @include desktop {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }
}
