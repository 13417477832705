/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

ul,
ol {
    ul,
    ol {
        counter-reset: list;
    }
}

li {
    &::before {
        position: absolute;
        inset-inline-start: 1em;
        inset-block-start: .07em;
        padding-inline-end: 1.1225em;

        ol > & {
            content: counter(list) '.';
        }

        &,
        ul > & {
            content: '\2022';
        }
    }
}
