/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.Breadcrumb {
    display: inline-block;
    line-height: 20px;
    text-transform: var(--base-secondary-text-transform);
    letter-spacing: $letter-spacing6;

    &:not(:last-child) {
        &::after {
            content: '/';
            color: var(--color-gray3);
            padding: 0 10px;
            font-size: 10px;
        }
    }

    .ChevronIcon {
        display: none;
    }

    &:last-child {
        margin-bottom: 0;

        :link {
            pointer-events: none;
        }

        .Breadcrumb-Link {
            color: var(--general-subtitle-secondry-color);
        }
    }

    &::before {
        content: none;
    }

    &-Link {
        display: inline-block;
        font-weight: $font-weight-medium;
        font-size: 10px;

        &:hover {
            color: var(--link-color);
        }

        &-Name {
            color: inherit;
        }
    }
}
