/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --install-prompt-android-background: var(--homepage-top-categories-background-color);
}

.InstallPromptAndroid {
    --button-border: transparent;
    --button-border-width: 0;

    background: var(--install-prompt-android-background);
    padding: 10px 45px 10px 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    &-Heading {
        @include mobile {
            margin-bottom: 5px;
            text-align: left;
            width: 100%;
            font-size: var(--base-secondary-small-font-size);
            font-weight: $font-weight-bold;
            line-height: 1;
        }
    }

    &-Content {
        @include mobile {
            align-items: center;
            display: flex;
            justify-content: left;
            width: 100%;
            font-size: var(--base-secondary-small-font-size);
            line-height: 1;
            margin: 0;
        }
    }

    &-Left {
        width: calc(100% - 130px);
    }

    &-Close {
        position: absolute;
        z-index: 10;
        right: 10px;
        top: 15px;
        width: 25px;
        height: 26px;
    }

    &-Button {
        --default-button-font-size: var(--base-secondary-font-size);
        --button-height: 40px;

        width: max-content;
    }
}
