/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.CompareIcon {
    @include icon-black-hover-effect;
    @include rtl-icon;

    cursor: pointer;

    @include compare-icon;

    &_isActive {
        &::before {
            color: var(--product-action-icon-active-color);
        }

        &:hover {
            &::before {
                color: var(--product-action-icon-active-color);
            }
        }
    }

    @include mobile {
        pointer-events: none;
    }
}
