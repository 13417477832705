/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --expandable-content-color: var(--color-karaka);
    --expandable-content-divider-color: var(--primary-divider-color);
    --toggle-button-size: 18px;
}

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}

.ExpandableContent {
    @include mobile {
        border-block-start: 1px solid var(--expandable-content-divider-color);

        &:last-of-type {
            border-block-end: 1px solid var(--expandable-content-divider-color);
        }

        .AddIcon,
        .MinusIcon,
        .ChevronIcon {
            width: var(--toggle-button-size);
            height: var(--toggle-button-size);
            display: none;
        }
    }

    &-Button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        @include mobile {
            padding-block: 10px;

            @include top-down-icon;
        }

        &_isContentExpanded {
            @include mobile {
                @include top-up-icon;
            }
        }
    }

    &-Heading,
    &-SubHeading {
        display: block;
        color: var(--expandable-content-color);
    }

    &-Heading {
        @include paragraph;
    }

    &-SubHeading {
        font-weight: 400;
        font-size: 10px;
        line-height: 1.1;

        @include mobile {
            font-size: 12px;
        }
    }

    &-Content {
        // Disabled as it prevents z-index use inside
        // will-change: max-height, padding, opacity;
        transition-property: opacity;
        transition-duration: 200ms;

        @include mobile {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
        }

        @include desktop {
            margin-top: 20px;
        }

        &_isContentExpanded {
            @include mobile {
                @include expanded-content;

                padding-top: 10px;
                padding-bottom: 30px;
            }
        }
    }

    @include mobile {
        &_isWithoutBorder {
            border: 0;

            &:last-of-type {
                border: 0;
            }
        }
    }
}
