/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --footer-content-height: 220px;
    --footer-copyright-height: 50px;
    --footer-nav-height: 50px;
    --footer-total-height: calc(env(safe-area-inset-bottom) + var(--footer-nav-height));
}

.Footer {
    $column-count: 4;

    @include mobile {
        background-color: var(--footer-background-color);
        padding-bottom: var(--navigation-tabs-height);
    }

    &-CopyrightContentWrapper {
        background-color: var(--footer-background-color);
    }

    &-CopyrightContent {
        padding: 25px 0 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: var(--footer-copyright-height);
        max-width: var(--footer-max-width);

        @include mobile {
            justify-content: center;
        }
    }

    &-Copyright {
        font-size: var(--footer-copyright-font-size);
        color: var(--footer-text-primary-color);
        text-transform: var(--footer-copyright-text-transform);
        letter-spacing: var(--footer-copyright-letter-spacing);
        line-height: 16px;

        a {
            color: inherit;
            font-size: var(--footer-copyright-font-size);

            &:hover {
                color: var(--primary-base-color);
            }
        }
    }

    &-Content {
        min-height: var(--footer-content-height);
        background-color: var(--footer-background-color);
    }

    &-ContentWrapper {
        padding-left: var(--layout-mobile-vertical-padding);
        padding-right: var(--layout-mobile-vertical-padding);
        padding-top: 40px;
        display: flex;
        width: 100%;

        @include mobile {
            flex-wrap: wrap;
            padding-left: 40px;
            padding-right: 40px;
        }

        @include desktop {
            max-width: var(--footer-max-width);
            padding-top: 70px;
            margin: auto;
        }

        @include desktop-xxl {
            padding-left: 0;
            padding-right: 0;
        }

        .Footer {
            &-ContentLeft {
                width: 100%;

                @include mobile {
                    text-align: center;
                }

                @include desktop {
                    width: calc(100% - 310px);
                }

                .CmsBlock-Wrapper {
                    @include desktop {
                        display: grid;
                        grid-template-columns: 310px 1fr;
                        column-gap: 20px;
                    }
                }

                .FooterLogo {
                    margin-bottom: 30px;
                }
            }

            &-ContentRight {
                width: 100%;

                @include desktop {
                    width: 310px;
                }
            }
        }
    }

    &-Columns {
        display: flex;
        height: 100%;
        justify-content: space-between;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-left: var(--layout-mobile-vertical-padding);
        padding-right: var(--layout-mobile-vertical-padding);

        @include mobile {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        @include desktop {
            padding-block-start: 70px;
        }

        @include desktop-xxl {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-Column {
        @include mobile {
            width: 100%;
        }

        img {
            width: auto;
            height: auto;
        }
    }

    &-ColumnSection {
        margin-bottom: 30px;

        &Title {
            @include h2;

            color: var(--footer-text-secondary-color);

            a {
                @include h2;

                color: var(--footer-text-secondary-color);
            }
        }

        &Desc {
            font-size: var(--footer-description-font-size);
            color: var(--footer-text-primary-color);

            @include mobile {
                text-align: center;
            }
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            @include mobile {
                justify-content: center;
            }

            li {
                &::before {
                    display: none;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        &.SocialMedia {
            .SocialMediaIcon {
                width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 20px;

                &::before {
                    transition: var(--general-icon-animation);
                }

                &.Insta {
                    @include insta-icon;
                }

                &.Facebook {
                    @include facebook-icon;
                }

                &.Twitter {
                    @include twitter-icon;
                }

                &.Youtube {
                    @include youtube-icon;
                }

                &:hover {
                    text-decoration: none;

                    &::before {
                        @include desktop {
                            color: var(--color-white);
                        }
                    }
                }
            }
        }
    }

    &-ColumnToggle {
        @include mobile {
            margin-bottom: 50px;
            text-align: left;
        }

        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 20px;
            padding-right: 20px;
        }

        .Footer-ColumnTitle {
            @include mobile {
                margin-bottom: 0;
            }
        }

        ul {
            li {
                &::before {
                    display: none;
                }

                &:last-of-type {
                    .Button_likeLink {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .Button_likeLink {
            --button-like-link-color: var(--color-white);
            --button-like-link-hover-color: var(--color-white);

            margin-bottom: 25px;

            @include mobile {
                justify-content: flex-start;
            }

            &:hover {
                @include desktop {
                    --button-like-link-hover-text-decoration: underline;
                }
            }
        }
    }

    &-ColumnTitle {
        margin-bottom: 20px;
        color: var(--footer-text-primary-color);
        font-size: var(--footer-title-font-size);
        font-weight: var(--footer-title-font-weight);
        letter-spacing: $letter-spacing4;
        text-transform: var(--base-secondary-text-transform);
        line-height: 19px;

        @include mobile {
            text-align: center;
        }

        @include desktop {
            pointer-events: none;
            user-select: none;
        }
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;

        &_direction_horizontal {
            flex-direction: row;

            @include mobile {
                justify-content: center;
            }
        }
    }

    &-ColumnItem {
        border-radius: 2px;
        margin-block-end: 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--footer-text-secondary-color);

        &:last-of-type {
            margin: 0;
        }

        &:hover {
            text-decoration: underline;
        }

        &Image {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                // important required as default 100% width is passed as inline style
                // + you can't achieve same centering with just passing '16px' as inline style for the case
                /* stylelint-disable-next-line declaration-no-important */
                width: 16px!important;
                inset-block-start: 50%;
                inset-inline-start: 50%;
                transform: translate(-50%, -50%);

                &:dir(rtl) {
                    transform: translate(50%, -50%);
                }

                &:hover {
                    opacity: 0.6;
                }
            }
        }

        &_type_image {
            width: 40px;
            height: 40px;
            border: 1px solid var(--secondary-dark-color);

            &:not(:last-of-type) {
                margin-inline-end: 20px;
            }
        }
    }

    &-CmsBlockWrapper {
        .CmsBlock-Wrapper {
            flex: 1;
        }

        .ContentWrapper .Footer-Columns {
            padding: 0;
        }

        .Footer-Column_isNewsletter {
            @include desktop {
                padding-inline-start: 100px;
            }
        }
    }

    &-AppIcons {
        @include mobile {
            margin-bottom: 50px;
        }

        ul {
            li {
                display: flex;
                height: 45px;
                align-items: center;
                justify-content: center;
                transition: var(--general-icon-animation);

                @include desktop {
                    border-radius: var(--general-border-radius);
                }

                &:hover {
                    @include desktop {
                        background-color: var(--color-gray11);
                    }
                }
            }
        }
    }

    &-AppIcon {
        display: flex;
    }

    .PaymentIcons {
        display: flex;
        flex-wrap: wrap;

        @include mobile {
            justify-content: center;
        }

        li {
            margin-right: 15px;
            margin-bottom: 20px;

            .PaymentIcon {
                display: flex;
            }
        }
    }

    &-Promo {
        background-color: var(--footer-promo-background-color);
        padding: 30px 0;

        @include mobile {
            padding-left: 20px;
            padding-right: 20px;
        }

        .CmsBlock-Wrapper {
            @include desktop {
                max-width: var(--layout-max-width);
                margin: auto;
            }
        }

        &-Items {
            display: flex;
            justify-content: space-between;

            @include mobile {
                flex-wrap: wrap;
                align-items: flex-start;
            }

            @include desktop {
                flex-direction: row;
            }
        }

        &-Item {
            display: flex;
            align-items: center;
            
            @include mobile {
                width: 48%;
                flex-wrap: wrap;
            }

            @include desktop {
                width: 24%;
            }

            &:nth-child(1),
            &:nth-child(2) {
                @include mobile {
                    margin-bottom: 30px;
                }
            }

            img {
                max-width: 60px;
                max-height: 60px;
                margin-right: 10px;
            }

            &-Text {
                @include mobile {
                    width: 100%;
                    margin-top: 10px;
                }

                h5 {
                    color: var(--footer-promo-title-color);
                }

                p {
                    color: var(--footer-promo-description-color);
                    font-size: var(--footer-description-font-size);
                    line-height: 20px;
                    margin-bottom: 0;

                    a {
                        font-size: var(--footer-description-font-size);
                        font-weight: $font-weight-semibold;
                        color: var(--footer-promo-link-size);
                    }
                }
            }
        }
    }
}
