/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

%loader {
    position: relative;
    width: 40px;
    height: 22px;
    background-color: var(--general-background-color);
    margin: 12px 0;
    border-left: solid 5px var(--primary-base-color);
    border-right: solid 5px var(--primary-base-color);
    box-sizing: border-box;
    animation: rotate 2s linear infinite;
    transform-origin: 50% 50%;

    &::before,
    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        width: 28px;
        height: 28px;
        transform: scaleY(.5774) rotate(-45deg);
        background-color: inherit;
        left: 1px;
        box-sizing: border-box;
    }

    // ltr + rtl adjusted, don't replace 'left' and 'right' here

    &::before {
        top: -14px;
        border-top: solid 7px var(--primary-base-color);
        border-right: solid 7px var(--primary-base-color);
    }

    &::after {
        bottom: -14px;
        border-bottom: solid 7px var(--primary-base-color);
        border-left: solid 7px var(--primary-base-color);
    }

    span {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 16px;
        height: 16px;
        background-color: var(--primary-light-color);
        border-radius: 50%;
        z-index: 2;
    }
}
