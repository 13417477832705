/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --newsletter-subscription-input-background: var(--input-background-color);
    --newsletter-subscription-placeholder-color: var(--secondary-dark-color);
    --newsletter-subscription-button-background: var(--link-color);
}

.NewsletterSubscription {
    margin-bottom: 50px;

    .FieldForm {
        &-Fieldset {
            &_isLoading {
                opacity: .7;
                pointer-events: none;
            }

            .Field {
                width: 100%;
                margin-block-start: 0;

                &-Message {
                    position: absolute;
                }

                input {
                    --input-background-color: transparent;
                    --input-padding: 0 40px 0 0;
                    --input-general-height: 39px;
                    
                    border-radius: 0;
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    font-size: var(--base-secondary-font-size);
                    color: var(--footer-text-secondary-color);

                    &::-webkit-input-placeholder {
                        font-style: italic;
                    }

                    &:focus {
                        @include desktop {
                            box-shadow: none;
                        }
                    }
                }
            }
        }

        &-Fields {
            margin-block-end: 0;
        }
    }

    .Form {
        &_isInvalid {
            margin-block-end: 18px;
        }
    }

    .Button {
        --hollow-button-background: transparent;
        --hollow-button-hover-background: transparent;
        --button-padding: 0;
        --button-border-width: 0;
        --button-height: 40px;

        position: absolute;
        right: 0;
        top: 0;
        margin-top: 0;

        @include mobile {
            width: auto;
        }

        @include email-icon;

        &:hover {
            &::before {
                @include desktop {
                    color: var(--color-white);
                }
            }
        }

        span {
            display: none;
        }
    }

    .Footer-ColumnSectionDesc {
        margin-bottom: 5px;
    }
}
