/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --homepage-block-widget-max-width: 1190px;
    --homepage-product-block-max-width: var(--homepage-block-widget-max-width);
    --homepage-featured-block-max-width: 1080px;
    --homepage-top-categories-background-color: var(--color-gray5);
    --homepage-top-categories-title-letter-spacing: #{$letter-spacing3};
    --homepage-top-categories-image-size: 150px;
}
 
.home-widget-block {
    .Image {
        height: auto;
        padding-bottom: 0;

        @include mobile {
            display: flex;
            margin-bottom: $indent-space1;
        }
    }

    .HomepageProductBlock {
        margin-bottom: 70px;

        @include mobile {
            padding-left: 18px;
            padding-right: 18px;
        }

        @include tablet {
            padding-left: 0;
            padding-right: 0;
        }

        @include desktop {
            grid-gap: 130px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            max-width: var(--homepage-product-block-max-width);
            margin-left: auto;
            margin-right: auto;
        }

        &-Figure {
            &:not(:last-of-type) {
                @include mobile {
                    margin-bottom: 50px;
                }
            }

            &:hover {
                @include desktop {
                    .Image {
                        img {
                            @include image-overlay;
                        }
                    }
                }
            }
        }

        .Image {
            margin-bottom: 15px;
            display: flex;
            width: 100%;

            img {
                transition: var(--general-icon-animation);
            }
        }

        &-Heading {
            margin-bottom: 15px;
        }
    }

    .HomepageFeaturedBlock {
        @include desktop {
            padding-top: 70px;

            &::after {
                content: '';
                background-color: var(--qty-selector-disabled-background-color);
                width: 100%;
                position: absolute;
                z-index: -1;
                max-height: 399px;
                height: 100%;
                top: 0;
                left: 0;
            }
        }

        &-Figure {
            @include mobile {
                padding-left: var(--layout-mobile-vertical-padding);
                padding-right: var(--layout-mobile-vertical-padding);
            }

            @include desktop {
                padding-left: var(--layout-mobile-vertical-padding);
                padding-right: var(--layout-mobile-vertical-padding);
                max-width: var(--homepage-featured-block-max-width);
                margin-left: auto;
                margin-right: auto;
                display: flex;
            }

            @include desktop-xxl {
                padding-left: 0;
                padding-right: 0;
            }

            &:hover {
                @include desktop {
                    .Image {
                        img {
                            @include image-overlay;
                        }
                    }
                }
            }

            .Image {
                @include mobile {
                    margin-left: -11px;
                    margin-right: -11px;
                    width: calc(100% + 22px);
                    margin-bottom: 0;
                }

                @include desktop {
                    width: calc(100% - 441px);
                    border-top-left-radius: var(--general-image-border-radius);
                    border-bottom-left-radius: var(--general-image-border-radius);
                    background-color: var(--general-background-color);
                }

                img {
                    transition: var(--general-icon-animation);

                    @include mobile {
                        border-radius: 0;
                    }

                    @include desktop {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
            }
        }

        &-Figcaption {
            background-color: var(--general-background-color);

            @include mobile {
                border-radius: var(--general-image-border-radius);
                margin-top: -$indent-space2;
                padding: $indent-space1;
                padding-bottom: 0;
            }

            @include desktop {
                width: 441px;
                border-top-right-radius: var(--general-image-border-radius);
                border-bottom-right-radius: var(--general-image-border-radius);
                padding: 40px;
            }

            h6 {
                margin-bottom: 10px;
            }

            h1 {
                margin-bottom: $indent-space1;
            }

            p {
                margin-bottom: 30px;
            }
        }
    }

    .HomepageTopCategories {
        background-color: var(--homepage-top-categories-background-color);

        &-Container {
            @include mobile {
                padding-top: 50px;
                padding-bottom: 70px;
                padding-left: var(--layout-mobile-vertical-padding);
                padding-right: var(--layout-mobile-vertical-padding);
            }

            @include desktop {
                max-width: var(--homepage-block-widget-max-width);
                margin-left: auto;
                margin-right: auto;
                padding: 70px 25px;
                display: flex;
                flex-wrap: wrap;
            }
        }

        &-Left {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            @include wide-desktop {
                width: calc(100% - 860px);
            }
        }

        &-Right {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            @include wide-desktop {
                max-width: 860px;
            }

            .block-title {
                display: none;
            }

            .block-top-cat {
                width: 100%;

                .top-category-items {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    column-gap: $indent-space1;

                    @include mobile {
                        row-gap: 30px;
                    }
        
                    @include desktop {
                        width: 100%;
                        grid-template-columns: repeat(4, 1fr);
                        row-gap: 50px;
                    }

                    @include wide-desktop {
                        max-width: 860px;
                    }

                    .category-item {
                        text-align: center;
                        margin-bottom: 0;
                        display: flex;
                        justify-content: center;

                        @include desktop {
                            padding-left: 25px;
                            padding-right: 25px;
                        }

                        .category-image {
                            width: 100%;
                            max-width: var(--homepage-top-categories-image-size);
                            height: var(--homepage-top-categories-image-size);
                            border-radius: 50%;
                            background-color: var(--general-background-color);
                            overflow: hidden;
                            display: flex;
                            align-items: center;
                            margin-bottom: 15px;
                            transition: transform 0.3s;

                            img {
                                height: auto;
                                border-radius: 0;
                            }
                        }

                        .category-name {
                            @include h5;

                            font-weight: $font-weight-medium;
                            line-height: $indent-space1;
                        }

                        > a {
                            &:hover {
                                @include desktop {
                                    .category-image {
                                        -webkit-transform: scale(1.1);
                                        transform: scale(1.1);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &-Title {
            @include mobile {
                margin-bottom: 30px;
            }

            @include narrow-desktop {
                text-align: center;
                width: 100%;
                margin-bottom: 30px;
            }

            p {
                color: var(--primary-base-color);
                letter-spacing: var(--homepage-top-categories-title-letter-spacing);
                margin-bottom: 5px;
            }
        }
    }

    &.recently-viewed {
        .RecentlyViewedWidget {
            .Image {
                height: 100%;
                padding-bottom: 100%;
                margin-bottom: 0;
            }
        }
    }

    &.home-blog {
        .post-list-container {
            .row {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;

                .post-list-item {
                    margin-left: 0 !important;

                    @include desktop {
                        width: 49.3%;
                    }

                    .post-image {
                        img {
                            border-radius: 0;
                            height: auto !important;
                        }
                    }

                    .post-info-wraper {
                        background-color: var(--general-background-color);
                        border-radius: var(--general-image-border-radius);
                        margin-top: -70px;
                        padding: 20px;
                        padding-bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);

                        @include mobile {
                            max-width: 78%;
                        }

                        @include desktop {
                            max-width: 420px;
                            width: 100%;
                            left: 55%;
                        }

                        .mp-post-title {
                            margin-bottom: 15px;
                        }

                        .mp-post-title,
                        .post-link-title {
                            @include h4;
                        }

                        .mp-post-info,
                        .post-short-description {
                            display: none;
                        }

                        .mp-read-more {
                            @include linkwithborder;
                        }
                    }

                    &:not(:last-of-type) {
                        @include mobile {
                            margin-bottom: 70px;
                        }
                    }

                    &:last-of-type {
                        .post-info-wraper {
                            @include desktop {
                                left: 45%;
                            }
                        }
                    }
                }
            }
        }
    }
}
