/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@keyframes placeholderAnimation {
    from { background-position: -1200px 0; }

    to { background-position: 1200px 0; }
}

@keyframes appear {
    from { opacity: 0; }

    to { opacity: 1; }
}

@keyframes rotate {
    from { transform: rotate(0); }

    to { transform: rotate(360deg); }
}
