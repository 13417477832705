/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.ResetAttributes {
    @include desktop {
        margin-block-end: 20px;
    }

    @include mobile {
        padding: 20px var(--layout-mobile-vertical-padding) 0;
        margin-block-end: 0;
    }

    &-AttributeValue {
        display: flex;
        align-items: center;
    }

    &-AttributeItem {
        margin-bottom: 15px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &-AttributeText {
        margin-left: 10px;
    }

    &-AttributeOption {
        img {
            height: 32px;
            object-fit: cover;
            vertical-align: middle;
            width: 32px;
        }

        .Image-Content {
            position: initial;
        }
    }

    &-CloseIcon {
        cursor: pointer;

        .CloseIcon {
            &::before {
                font-size: 16px;
                line-height: 16px;
                font-weight: $font-weight-bold;
                color: var(--input-general-border-color);
                transition: $transition1;
            }
        }

        &:hover {
            @include desktop {
                .CloseIcon {
                    &::before {
                        color: var(--cart-count-background-color);
                    }
                }
            }
        }
    }

    &-Title {
        @include subtitle;

        text-transform: var(--base-secondary-text-transform);
        padding-bottom: $indent-space1;

        @include mobile {
            display: none;
        }
    }

    &-MobileTitle {
        @include mobile {
            padding-top: 20px;
            padding-left: var(--layout-mobile-vertical-padding);
            padding-right: var(--layout-mobile-vertical-padding);
            text-transform: uppercase;

            @include subtitle;
        }

        @include desktop {
            display: none;
        }
    }
}
