/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.ContactPage {
    &-Heading {
        margin-bottom: 20px;
    }

    &-ColumnWrapper {
        display: flex;
        padding-bottom: 80px;

        @include desktop {
            display: grid;
            justify-content: space-around;
            margin: 0 auto;
            grid-template-columns: 420px auto;
            column-gap: 130px;
            max-width: var(--footer-max-width);
            align-items: flex-start;
        }

        @include mobile {
            flex-wrap: wrap;
        }
    }

    &-Column {
        @include desktop {
            flex: 1;
        }

        @include mobile {
            order: 2;
        }

        &_isContent {
            @include desktop {
                padding-top: 50px;
            }

            @include mobile {
                order: 1;
                width: 100%;
                padding-top: 30px;
                padding-bottom: 50px;
            }
        }
    }

    &-Desc {
        margin-bottom: 30px;
    }

    .Form {
        .Button {
            @include mobile {
                width: 100%;
            }
        }
    }
}
