/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --help-switcher-width: 270px;
    --help-switcher-height: 180px;
    --help-switcher-icon-font-size: 20px;
}

.HelpSwitcher {
    @include desktop {
        padding: 0 0 0 20px;
    }

    @include mobile {
        display: flex;
        width: 100%;
    }

    &-Title {
        @include arrow-down-icon;

        line-height: 20px;
        font-size: var(--header-top-menu-font-size);
        font-weight: var(--header-top-menu-font-weight);
        color: var(--header-top-menu-color);
        cursor: pointer;
        transition: $transition1;
        display: flex;
        align-items: center;

        span {
            color: var(--header-top-menu-color);

            @include mobile {
                color: var(--menu-mobile-other-links-color);
            }
        }

        @include mobile {
            @include arrow-right-icon;

            justify-content: space-between;
            display: flex;
            width: 100%;
            cursor: pointer;
            color: var(--menu-mobile-other-links-color);
            font-size: var(--menu-sub-item-sub-font-size);
            font-weight: $font-weight-regular;
        }

        &::after {
            @include desktop {
                margin-left: 5px
            }
        }

        &_isActive {
            @include desktop {
                @include arrow-up-icon;
            }

            span {
                @include desktop {
                    text-decoration: underline;
                }
            }
        }
    }

    &-HelpSwitcherList {
        @include desktop {
            position: absolute;
            opacity: 0;
            visibility: hidden;
            min-width: var(--help-switcher-width);
            min-height: var(--help-switcher-height);
        }

        @include mobile {
            opacity: 0;
            pointer-events: none;
            touch-action: pan-y;
            transform: translateX(100%);
            transition: transform .2s ease-in-out;
            height: 100%;
            left: 0;
            padding-top: var(--header-total-height);
            padding-bottom: var(--footer-nav-height);
            position: fixed;
            top: 0;
            width: 100%;
            background: var(--color-white);
            overflow-y: hidden;
        }

        &_isActive {
            @include desktop {
                right: 0;
                opacity: 1;
                visibility: visible;
                z-index: 1;
                margin-top: 9px;
                min-width: var(--help-switcher-width);
                box-shadow: $box-shadow1;
                border-radius: 5px;
                background-color: var(--overlay-background);
            }

            @include desktop-xxl {
                right: -20px;
            }

            @include mobile {
                pointer-events: all;
                z-index: 10;
                opacity: 1;
                transform: translateX(0);
                padding-inline: 0;
                margin-block-end: 0;
                background: var(--color-white);
            }

            &::before {
                @include desktop {
                    right: 18px;
                    content: '';
                    position: absolute;
                    inset-block-start: -6px;
                    height: 20px;
                    width: 20px;
                    transform: rotate(45deg);
                    background-color: var(--overlay-background);
                    margin: auto;
                    box-shadow: $box-shadow1;
                    z-index: 2;
                }
                
                @include desktop-xxl {
                    right: 38px;
                }
            }
        }

        ul {
            background-color: var(--overlay-background);

            @include mobile {
                padding-left: 10px;
                padding-right: 10px;
            }
            
            @include desktop {
                position: absolute;
                z-index: 3;
                padding-block-start: 10px;
                min-width: var(--help-switcher-width);
            }

            li {
                margin-bottom: 0;

                &::before {
                    display: none;
                }
            }
        }
    }

    &-HelpItem {
        @include paragraph;

        padding-top: var(--layout-mobile-vertical-padding);
        padding-bottom: var(--layout-mobile-vertical-padding);
        font-size: 11px;
        line-height: 16px;
        color: var(--general-paragraph-color);
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        text-align: left;

        @include desktop {
            padding-left: 55px;
            padding-right: 20px;
            padding-top: 7px;
            padding-bottom: 7px;
        }
        
        @include mobile {
            padding-left: 32px;
            border-bottom: 1px solid var(--primary-divider-color);
        }

        span {
            width: 100%;
            color: var(--general-paragraph-color);

            &.HelpSwitcher-SubTitle {
                font-size: var(--base-secondary-font-size);
                line-height: 20px;
                font-weight: $font-weight-medium;
                color: var(--primary-dark-color);
            }

            a {
                --link-color: var(--primary-dark-color);
                
                font-size: var(--base-secondary-font-size);
                font-weight: $font-weight-medium;

                &:hover {
                    @include desktop {
                        text-decoration: none;
                    }
                }
            }
        }

        &:hover {
            @include desktop {
                background-color: var(--general-container-background-color);
                text-decoration: none;
            }
        }
    }

    &-LiveChat {
        @include chat-icon;

        &::before {
            position: absolute;
            left: 0;
            top: 10px;
            width: var(--help-switcher-icon-font-size);

            @include desktop {
                left: 20px;
                top: 8px;
            }
        }
    }
    
    &-Contact {
        @include contact-icon;

        &::before {
            position: absolute;
            left: 0;
            top: 10px;
            width: var(--help-switcher-icon-font-size);

            @include desktop {
                left: 23px;
                top: 7px;
            }
        }
    }

    &-Call {
        @include telephone-icon;

        &::before {
            position: absolute;
            left: 0;
            top: 10px;
            width: var(--help-switcher-icon-font-size);

            @include desktop {
                left: 23px;
                top: 7px;
            }
        }
    }
}
