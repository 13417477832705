/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --search-item-hover-color: var(--color-gray5);
    --search-item-divider-color: #eee;
    --search-item-title-color: #000;
}

.SearchItem {
    padding: 7px 20px;

    &::before {
        content: none;
    }

    &:hover {
        background-color: var(--search-item-hover-color);
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &-Content {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
    }

    &-CustomAttribute {
        color: var(--color-black);
        font-size: var(--base-secondary-small-font-size);
        font-weight: 400;
        opacity: .5;
        display: none;
    }

    &-Wrapper {
        display: flex;
        width: 100%;

        .Image {
            display: none;
        }
    }

    &-Title {
        font-size: var(--base-secondary-font-size);
        line-height: 18px;
        letter-spacing: $letter-spacing2;
    }
}
