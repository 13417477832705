/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.ForgotPassword {
    @include desktop {
        background-color: var(--general-container-background-color);
    }

    .ContentWrapper {
        padding-bottom: 100px;

        @include desktop {
            padding-top: 50px;
        }
    }

    &-CreateAccountButton,
    &-SignInButton {
        width: 100%;
    }

    &-Input {
        &_type_email {
            width: 100%;
        }
    }

    &-InnerWrapper {
        @include mobile {
            padding-top: 20px;
        }

        @include desktop {
            display: grid;
            justify-content: space-around;
            margin: 0 auto;
            padding-block-start: 0;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;
            max-width: 860px;
            align-items: flex-start;
        }

        .MyAccountOverlay {
            &-Additional {
                display: none;
            }
        }

        button {
            margin-top: 20px;
        }

        button,
        input {
            width: 100%;
        }

        .Field {
            &_type_email {
                @include mobile {
                    margin-top: 10px;
                }
            }
        }

        > div {
            flex: 1;
            border-radius: var(--general-border-radius);
            background-color: var(--input-general-background-color);
            padding: var(--general-container-padding);

            &.ForgotPassword {
                &-AdditionalContent {
                    .Button {
                        margin-top: 0;
                    }
                }
            }
        }

        h3 {
            margin-bottom: 20px;
            text-align: center;
        }

        p {
            margin-block-end: 20px;
        }
    }

    &-SignInWrapper {
        margin-top: 50px;
    }

    .Loader {
        margin: 0;
    }
}
