/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --navigation-tabs-height: calc(50px + env(safe-area-inset-bottom));
    --navigation-tabs-background: var(--general-background-color);
    --navigation-tabs-color: var(--secondary-dark-color);
}

.NavigationTabs {
    --header-background: var(--navigation-tabs-background);
    --header-icon-stroke-width: 2px;

    position: fixed;
    inset-block-end: 0;
    inset-inline-start: 0;
    width: 100%;
    z-index: 100;
    background-color: var(--header-background);
    padding-block-end: env(safe-area-inset-bottom);
    transform: translateY(0);
    transition: transform 200ms cubic-bezier(.47, 0, .745, .715);

    .hideOnScroll & {
        transform: translateY(100%);
    }

    @include mobile {
        box-shadow: var(--menu-mobile-tab-container-box-shadow);
    }

    @include desktop {
        display: none;
    }

    &_isHidden {
        display: none;

        @at-root .hiddenNavigationTabs {
            --navigation-tabs-height: env(safe-area-inset-bottom);
        }
    }

    &-Nav {
        height: var(--footer-nav-height);
        display: flex;
        align-items: center;
        justify-content: space-around;
        z-index: 20;
        max-width: var(--content-wrapper-width);
        margin: auto;
    }

    &-Button {
        flex-grow: 1;
        height: 100%;
        display: inherit;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @include mobile {
            max-width: 70px;
        }
    }

    &-Icon {
        font-weight: bold;
    }

    &-Home {
        @include home-mobile-icon;

        &_isActive {
            &::before {
                color: var(--menu-mobile-tab-icon-hover-color);
            }
        }
    }

    &-Menu {
        @include menu-mobile-icon;

        &_isActive {
            &::before {
                color: var(--menu-mobile-tab-icon-hover-color);
            }
        }
    }

    &-User {
        @include account-mobile-icon;

        &_isActive {
            &::before {
                color: var(--menu-mobile-tab-icon-hover-color);
            }
        }
    }

    &-MiniCart {
        @include minicart-mobile-icon;

        &_isActive {
            &::before {
                color: var(--menu-mobile-tab-icon-hover-color);
            }
        }
    }
}
