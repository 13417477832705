/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

$white: #fff;
$black: #000;
$default-primary-base-color: #2C5979;
$default-primary-dark-color: #0D2B40;
$default-primary-light-color: #46B3C1;
$default-secondary-base-color: #4DD2E3;
$default-secondary-dark-color: #0B8493;
$default-secondary-light-color: #595959;
$font-muli: 'Muli', sans-serif;
$font-arial: 'Arial', sans-serif;
$font-standard-size: 62.5%;

// Colors
$grey1: #b1b1b1;
$grey2: #e1e8ed;
$grey3: #848a8e;
$grey4: #f2f5f7;
$grey5: #f7f7f7;
$grey6: #fcfcfc;
$grey7: #586167;
$grey8: #D9D9D9;
$grey9: #F0F0F0;
$grey10: #E8E8E8;
$grey11: #1a1a1a;
$grey12: #CAD5D6;
$grey13: #B3B3B3;
$grey14: #DADCE3;

$blue1: #96AAB9;
$blue2: #F0F6FA;
$blue3: #073250;
$blue4: #071F2F;
$blue5: #778598;

$red1: #D82432;
$red2: #FAF0F1;

$green1:#2B784F;
$green2:#F0FAF5;

// Layout
$layout__max-width: 1300px;

//  Overlay
$overlay1: rgba(0,0,0,0.5);

// Box Shadow
$box-shadow1: 0px 3px 35px rgba(0, 0, 0, 0.1);
$box-shadow2: 0px 3px 10px rgba(77, 210, 227, 0.26);
$box-shadow3: 0px 3px 50px rgba(0, 0, 0, 0.07);
$box-shadow4: inset 0 6px 15px -4px rgba(0, 0, 0, 0.07);
$box-shadow5: 0px -10px 40px rgba(0, 0, 0, 0.05);
$box-shadow6: 0px 1px 5px rgba(0, 0, 0, 0.1);
$box-shadow7: 0px 10px 40px rgba(0, 0, 0, 0.05);
$box-shadow8: 0px 3px 5px rgba(0, 0, 0, 0.04);

// Animation
$transition1: all .5s;
$transition2: ease-out .25s;

// Icons
$icomoon-font-family: "icomoon-font-family" !default;

// Typography
$font-jost-sans: 'Jost', sans-serif;
$base-font-family: $font-jost-sans;

$font-weight-extrabold: 900;
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;

$letter-spacing1: 0.42px;
$letter-spacing2: 0.38px;
$letter-spacing3: 3.2px;
$letter-spacing4: 2.6px;
$letter-spacing5: 2.4px;
$letter-spacing6: 1.5px;
$letter-spacing7: 1.8px;
$letter-spacing8: 0.85px;
$letter-spacing9: 1.95px;

$indent-space0: 5px;
$indent-space1: 20px;
$indent-space2: 30px;

$h1-font-color: var(--primary-dark-color);
$h1-font-weight: $font-weight-bold;
$h1-letter-spacing: 0;
$h1-letter-spacing-mobile: $h1-letter-spacing;
$h1-font-size-desktop: 45px;
$h1-font-size-mobile: 35px;
$h1-font-line-height-desktop: 50px;
$h1-font-line-height-mobile: 40px;

$h2-font-color: var(--primary-dark-color);
$h2-font-weight: $font-weight-bold;
$h2-letter-spacing: 0;
$h2-letter-spacing-mobile: $h2-letter-spacing;
$h2-font-size-desktop: 27px;
$h2-font-size-mobile: $h2-font-size-desktop;
$h2-font-line-height-desktop: 32px;
$h2-font-line-height-mobile: $h2-font-line-height-desktop;

$h3-font-color: var(--primary-dark-color);
$h3-font-weight: $font-weight-bold;
$h3-letter-spacing: 0;
$h3-font-size-desktop: 23px;
$h3-font-size-mobile: $h3-font-size-desktop;
$h3-font-line-height-desktop: 30px;
$h3-font-line-height-mobile: $h3-font-line-height-desktop;

$h4-font-color: var(--primary-dark-color);
$h4-font-weight: $font-weight-semibold;
$h4-letter-spacing: 0;
$h4-letter-spacing-mobile: $h4-letter-spacing;
$h4-font-size-desktop: 20px;
$h4-font-size-mobile: $h4-font-size-desktop;
$h4-font-line-height-desktop: 28px;
$h4-font-line-height-mobile: $h4-font-line-height-desktop;

$h5-font-color: var(--primary-dark-color);
$h5-font-weight: $font-weight-semibold;
$h5-letter-spacing: 0;
$h5-font-size-desktop: 17px;
$h5-font-size-mobile: $h5-font-size-desktop;
$h5-font-line-height-desktop: 24px;
$h5-font-line-height-mobile: $h5-font-line-height-desktop;

$h6-font-color: var(--color-gray3);
$h6-font-weight: $font-weight-regular;
$h6-letter-spacing: #{$letter-spacing3};
$h6-font-size-desktop: 15px;
$h6-font-size-mobile: $h6-font-size-desktop;
$h6-font-line-height-desktop: 23px;
$h6-font-line-height-mobile: $h6-font-line-height-desktop;

$subheading-font-family: $base-font-family;
$subheading-font-color: var(--label-general-text-color);
$subheading-font-weight: var(--label-general-font-weight);
$subheading-letter-spacing: 0;
$subheading-font-size-desktop: var(--base-secondary-font-size);
$subheading-font-line-height-desktop: 22px;

$subheading2-font-family: $base-font-family;
$subheading2-font-color: var(--general-subtitle-secondry-color);
$subheading2-font-weight: $font-weight-medium;
$subheading2-letter-spacing: $letter-spacing9;
$subheading2-font-size-desktop: 13px;
$subheading2-font-line-height-desktop: 19px;

$subtitle-font-family: $base-font-family;
$subtitle-font-color: var(--general-subtitle-color);
$subtitle-font-weight: $font-weight-semibold;
$subtitle-letter-spacing: $letter-spacing7;
$subtitle-font-size-desktop: 12px;
$subtitle-font-line-height-desktop: 18px;

$paragraph-font-family: $base-font-family;
$paragraph-font-color: var(--general-paragraph-color);
$paragraph-font-weight: $font-weight-regular;
$paragraph-letter-spacing: 0;
$paragraph-font-size-desktop: 17px;
$paragraph-font-size-mobile: $paragraph-font-size-desktop;
$paragraph-font-line-height-desktop: 28px;
$paragraph-font-line-height-mobile: $paragraph-font-line-height-desktop;
