/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --table-body-border: 1px solid rgba(10, 9, 3, 0.16);
}

table {
    thead th {
        background-color: var(--color-gray);
        border-radius: 2px;
    }

    tbody tr:not(:last-child) {
        border-block-end: var(--table-body-border) ;
    }

    tr {
        page-break-inside: avoid;
    }

    td,
    th {
        padding: 10px;
        text-align: start;
        min-width: 120px;
        vertical-align: top;

        @include mobile {
            min-width: 140px;
        }
    }
}

.Table-Wrapper {
    max-width: 100%;
    overflow-x: auto;
}
