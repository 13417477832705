/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.ImageZoomPopup {
    height: 100%;

    &.Popup {
        height: 100%;
    }

    &-PopupContent.Popup-Content {
        max-height: 100vh;
        height: 100vh;
        max-width: 100vw;
        width: 100%;
        padding: 0;

        .Popup-CloseBtn {
            top: 20px;
            right: 30px;
        }
    }

    .ProductGallery {
        height: 100%;

        &-SliderWrapper {
            height: 100%;
        }
    }

    .Slider-Arrow {
        display: none;
    }
}
