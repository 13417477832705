/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@mixin h1 {
    color: $h1-font-color;
    font-size: $h1-font-size-desktop;
    line-height: $h1-font-line-height-desktop;
    font-weight: $h1-font-weight;
    letter-spacing: $h1-letter-spacing;

    @include mobile {
        font-size: $h1-font-size-mobile;
        line-height: $h1-font-line-height-mobile;
    }
}

@mixin h2 {
    color: $h2-font-color;
    font-size: $h2-font-size-desktop;
    line-height: $h2-font-line-height-desktop;
    font-weight: $h2-font-weight;
    letter-spacing: $h2-letter-spacing;

    @include mobile {
        font-size: $h2-font-size-mobile;
        line-height: $h2-font-line-height-mobile;
    }
}

@mixin h3 {
    color: $h3-font-color;
    font-size: $h3-font-size-desktop;
    line-height: $h3-font-line-height-desktop;
    font-weight: $h3-font-weight;
    letter-spacing: $h3-letter-spacing;

    @include mobile {
        font-size: $h3-font-size-mobile;
        line-height: $h3-font-line-height-mobile;
    }
}

@mixin h4 {
    color: $h4-font-color;
    font-size: $h4-font-size-desktop;
    line-height: $h4-font-line-height-desktop;
    font-weight: $h4-font-weight;
    letter-spacing: $h4-letter-spacing;

    @include mobile {
        font-size: $h4-font-size-mobile;
        line-height: $h4-font-line-height-mobile;
    }
}

@mixin h5 {
    color: $h5-font-color;
    font-size: $h5-font-size-desktop;
    line-height: $h5-font-line-height-desktop;
    font-weight: $h5-font-weight;
    letter-spacing: $h5-letter-spacing;

    @include mobile {
        font-size: $h5-font-size-mobile;
        line-height: $h5-font-line-height-mobile;
    }
}

@mixin h6 {
    color: $h6-font-color;
    font-size: $h6-font-size-desktop;
    line-height: $h6-font-line-height-desktop;
    font-weight: $h6-font-weight;
    letter-spacing: $h6-letter-spacing;

    @include mobile {
        font-size: $h6-font-size-mobile;
        line-height: $h6-font-line-height-mobile;
    }
}

@mixin subheading {
    font-family: $subheading-font-family;
    font-weight: $subheading-font-weight;
    color: $subheading-font-color;
    letter-spacing: $subheading-letter-spacing;
    font-size: $subheading-font-size-desktop;
    line-height: $subheading-font-line-height-desktop;
}

@mixin subheading2 {
    font-family: $subheading2-font-family;
    font-weight: $subheading2-font-weight;
    color: $subheading2-font-color;
    letter-spacing: $subheading2-letter-spacing;
    font-size: $subheading2-font-size-desktop;
    line-height: $subheading2-font-line-height-desktop;
}

@mixin subtitle {
    font-family: $subtitle-font-family;
    font-weight: $subtitle-font-weight;
    color: $subtitle-font-color;
    letter-spacing: $subtitle-letter-spacing;
    font-size: $subtitle-font-size-desktop;
    line-height: $subtitle-font-line-height-desktop;
}

@mixin paragraph {
    font-family: $paragraph-font-family;
    font-weight: $paragraph-font-weight;
    color: $paragraph-font-color;
    letter-spacing: $paragraph-letter-spacing;
    font-size: $paragraph-font-size-desktop;
    line-height: $paragraph-font-line-height-desktop;
}

@mixin linkstyle1 {
    font-family: $paragraph-font-family;
    font-weight: var(--button-like-link-font-weight);
    color: var(--button-like-link-color);
    letter-spacing: var(--button-like-link-letter-spacing);
    font-size: var(--button-like-link-font-size);
    line-height: 24px;

    &:hover {
        @include desktop {
            color: var(--button-like-link-hover-color);
            text-decoration: var(--button-like-link-hover-text-decoration);
        }
    }
}

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}

// Image Overlay 

@mixin image-overlay {
    transition: var(--general-icon-animation);
    opacity: var(--general-image-hover-opacity);
}

// Link with Border

@mixin linkwithborder {
    font-size: var(--button-like-link-font-size);
    color: var(--button-like-link-color);
    font-weight: var(--button-like-link-font-weight);
    letter-spacing: var(--button-like-link-letter-spacing);
    text-transform: var(--default-button-text-transform);
    line-height: 24px;
    display: inline-block;

    &::after {
        content: '';
        background-color: var(--primary-light-color);
        height: 1px;
        display: flex;
        width: 100%;
    }

    &:hover {
        @include desktop {
            text-decoration: none;
            color: var(--button-like-link-hover-color);

            &::after {
                background-color: var(--button-like-link-hover-color);
            }
        }
    }
}

// Qty Selector

@mixin qty-selector {
    margin-top: 0;
    display: flex;
    cursor: default;

    input {
        --input-height: 28px;

        background: transparent;
        border: 0;
        color: var(--primary-dark-color);
        text-align: center;
        padding: 0;
        order: 2;
        min-width: 49px;
        min-height: 28px;
        border-bottom: 1px solid var(--primary-divider-color);
        border-radius: 0;

        @include paragraph;

        &::-webkit-inner-spin-button {
            display: none;
        }
    }

    button {
        position: relative;
        order: 1;
        background-color: var(--qty-selector-background-color);
        width: var(--qty-selector-width);
        height: var(--qty-selector-width);
        left: 0;
        color: var(--qty-selector-color);
        border-radius: 2rem;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-of-type {
            @include qty-plus-thin-icon;

            left: 0;
            order: 3;

            &::before {
                margin-left: 0;
                margin-top: 1px;
            }
        }

        &:last-of-type {
            @include qty-minus-thin-icon;

            right: 0;
            order: 1;

            &::before {
                margin-left: 0;
                margin-top: 1px;
            }
        }

        &:hover {
            @include desktop {
                background-color: var(--qty-selector-hover-background-color);
            }
        }

        &:disabled {
            background-color: var(--qty-selector-disabled-background-color);
            opacity: 1;

            &::before {
                color: var(--qty-selector-disabled-icon-color);
            }
        }

        &:not([disabled]) {
            cursor: pointer;
        }
    }
}

// Custom Scroll

@mixin custom-scroll {
    /* width */
    
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */

    &::-webkit-scrollbar-track {
        background: #{$grey5};
    }

    /* Handle */

    &::-webkit-scrollbar-thumb {
        background: #{$grey10}; 
        border-radius: 10px;
        border: 7px solid #{$grey10};
    }
}

// Name Inputs

@mixin first-and-last-single {
    &:nth-child(1),
    &:nth-child(2) {
        width: 48.5%;
        float: left;
        margin-bottom: 10px;

        .Field {
            margin-top: 0;
        }
    }

    &:nth-child(1) {
        margin-right: 3%;
    }

    &:nth-child(3) {
        clear: both;
    }
}

// Title Dot 

@mixin title-dot-before {
    &::before {
        width: 10px;
        height: 10px;
        content: '';
        background-color: var(--secondary-base-color);
        display: inline-block;
    }
}

// Optinal Text

@mixin optional-text {
    &::after {
        @include h6;

        content: '(optional)';
        line-height: 18px;
        color: var(--input-general-text-color);
        letter-spacing: 0;
        margin-left: 5px;
    }
}

// Cart Discount

@mixin cart-discount {
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--order-summary-background-color);
    border-bottom-left-radius: var(--general-border-radius);
    border-bottom-right-radius: var(--general-border-radius);
    border-top: 0;

    @include mobile {
        padding-left: var(--layout-mobile-vertical-padding);
        padding-right: var(--layout-mobile-vertical-padding);
    }

    &:first-of-type {
        @include mobile {
            border-width: 1px;
        }
    }

    &:last-of-type {
        @include mobile {
            border-bottom: 0;
        }
    }

    .ExpandableContent {
        &-Button {
            border-top: 1px solid var(--primary-divider-color);
            padding-top: 20px;
            padding-bottom: 25px;
            margin-bottom: 0;
            justify-content: inherit;
            cursor: pointer;

            @include discount-code-icon;
            @include top-down-icon;

            &::before {
                margin-right: 10px;
            }

            &::after {
                font-size: 9px;
                position: absolute;
                right: 0;
            }

            svg {
                display: none;
            }

            &_isContentExpanded {
                @include top-up-icon;

                padding-bottom: 0;

                &::after {
                    font-size: 9px;
                }
            }
        }

        &-Content {
            &_isContentExpanded {
                padding-bottom: 25px;
            }
        }

        &-Heading {
            @include h5;

            font-weight: $font-weight-medium;
            line-height: 18px;
            cursor: pointer;
            color: var(--order-summary-coupon-code-color);

            @include mobile {
                padding-right: 15px;
            }

            @include optional-text;
        }
    }
}

// Order List Table

@mixin order-list-table {
    &-HeadingContainer {
        .KeyValueTable {
            &-Heading {
                padding-right: 0;

                &:last-of-type {
                    text-align: right;
                    padding-right: 0;
                }
            }
        }
    }

    &-Column {
        padding-right: 0;
        min-width: auto;

        &:last-of-type {
            text-align: right;
            padding-right: 0;
        }
    }

    &-Row {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(3, 1fr);

        @include desktop {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 20px;
        }

        @include mobile {
            padding-left: var(--layout-mobile-vertical-padding);
            padding-right: var(--layout-mobile-vertical-padding);
        }

        .KeyValueTable {
            &-Heading,
            &-Column {
                &:nth-child(2) {
                    text-align: center;
                }

                &:nth-child(3) {
                    @include mobile {
                        text-align: right;
                    }

                    @include desktop {
                        text-align: center;
                    }
                }
            }
        }
    }
}
