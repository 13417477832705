/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --header-logo-width: 194px;
    --header-logo-height: 38px;
    --header-height: 50px;
    --header-nav-height: 50px;
    --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + env(safe-area-inset-top));

    @include desktop {
        --header-height: 114px;
    }
}

@mixin button-invisible {
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    width: 0;
    height: 0;
}

@mixin button-visible {
    opacity: 1;
    height: var(--header-icons-width);
    width: var(--header-icons-width);
    pointer-events: all;
    overflow: visible;
    cursor: pointer;
}

@mixin visible-on-mobile {
    @include button-visible;

    @include desktop {
        @include button-invisible;
    }
}

@mixin logo-visible {
    opacity: 1;
    max-width: 100%;
}

.Header {
    align-items: center;
    background: var(--color-white);
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-block-start: env(safe-area-inset-top, 0);
    width: 100%;
    position: fixed;
    z-index: 100;
    inset-block-start: var(--demo-notice-height);

    @include desktop {
        box-shadow: var(--header-box-shadow);
    }

    > * {
        max-width: var(--layout-max-width);
        width: 100%;
    }

    &_menu,
    &_menu_subcategory {
        .Header-Button_type {
            &_close {
                @include desktop {
                    @include button-visible;
                }
            }

            &_menu {
                @include desktop {
                    @include button-invisible;
                }
            }
        }
    }

    &_name {
        &_menu {
            border-block-end: none;
        }

        &_category {
            position: fixed;

            @include mobile {
                border-block-end: none;
            }
        }
    }

    &-Button {
        @include button-invisible;

        &_isVisible {
            @include mobile {
                @include button-visible;
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: .1;
        }

        &_type {
            &_menu,
            &_minicart {
                @include button-visible;

                @include desktop {
                    margin-left: var(--header-icons-width);
                }
            }

            &_account {
                @include button-visible;
            }

            &_home {
                @include visible-on-mobile;
            }

            &_minicart {
                @include desktop {
                    margin-left: var(--header-icons-width);
                }
            }

            &_back {
                @include go-back-icon;

                .ChevronIcon {
                    display: none;
                }
            }

            &_close {
                height: 20px;

                .CloseIcon {
                    &::before {
                        font-size: 18px;
                        line-height: 18px;
                    }
                }
            }

            &_share {
                @include button-visible;
            }

            &_compare {
                @include button-visible;
            }
        }

        &[aria-hidden="true"] {
            display: none;
        }
    }

    &-IconsWrapper {
        display: flex;
        justify-content: flex-end;

        @include desktop {
            order: 3;
        }
    }

    &-MinicartButtonWrapper {
        cursor: pointer;
        height: 100%;
        width: 100%;
        
        @include minicart-icon;

        &:hover {
            &::after {
                transition: var(--general-icon-animation);
                
                @include desktop {
                    color: var(--general-icon-hover-color);
                }
            }
        }
    }

    &-CompareButtonWrapper {
        a {
            &.Header-Button_type_compare {
                line-height: 20px;

                @include desktop {
                    font-size: var(--header-top-menu-font-size);
                    font-weight: var(--header-top-menu-font-weight);
                    color: var(--header-top-menu-color);
                }

                &:hover {
                    @include desktop {
                        text-decoration: var(--header-top-menu-text-decoration);
                    }
                }
            }
        }
    }
    
    &-LogoWrapper {
        inset-block-end: 0;
        height: var(--header-logo-height);
        inset-inline-start: 0;
        max-width: 0;
        opacity: 0;
        overflow: hidden;
        inset-inline-end: 0;
        inset-block-start: 0;
        width: var(--header-logo-width);

        @include mobile {
            --header-logo-width: var(--header-logo-mobile-width);
            --header-logo-height: var(--header-logo-mobile-height);
        }

        @include desktop {
            --header-logo-width: var(--header-logo-desktop-width);
            --header-logo-height: var(--header-logo-desktop-height);

            grid-column: 1 / 2;

            @include logo-visible;
        }

        &_isVisible {
            @include logo-visible;
        }
    }

    &-Minicart {
        &ItemCount {
            background: var(--cart-count-background-color);
            border-radius: 9px;
            color: var(--cart-count-color);
            padding: 0 2px;
            height: 18px;
            position: absolute;
            min-width: 18px;
            display: flex;
            font-size: var(--cart-count-font-size);
            justify-content: center;
            align-items: center;
            font-weight: var(--cart-count-font-weight);
            top: -5px;
            right: -12px;

            @include mobile {
                top: -4px;
                right: -9px;
            }
        }
    }

    &-Nav {
        align-items: center;
        display: flex;
        margin: auto;
        max-width: var(--layout-max-width);
        padding-left: var(--layout-mobile-vertical-padding);
        padding-right: var(--layout-mobile-vertical-padding);

        @include mobile {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            height: var(--header-nav-height);
            box-shadow: $box-shadow7;
        }

        @include desktop {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;
        }

        @include desktop-xxl {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-MyAccount {
        @include desktop {
            height: var(--header-icons-width);
        }
    }

    &-MyAccountWrapper {
        cursor: pointer;

        @include account-icon;

        &:hover {
            &::after {
                transition: var(--general-icon-animation);
                
                @include desktop {
                    color: var(--general-icon-hover-color);
                }
            }
        }
    }

    &-News {
        --link-color: var(--header-top-menu-color);

        font-size: var(--header-top-menu-font-size);
        font-weight: var(--header-top-menu-font-weight);
        color: var(--header-top-menu-color);
        line-height: 20px;
        display: none;
        
        @include mobile {
            align-items: center;
            display: flex;
        }

        @include desktop {
            padding-right: 20px;
        }

        a {
            --link-hover: var(--header-top-menu-color);

            font-weight: var(--header-top-menu-font-weight);

            &:hover {
                @include desktop {
                    text-decoration: var(--header-top-menu-text-decoration);
                }
            }
        }

        &Button {
            font-size: var(--header-top-menu-font-size);
            margin-inline-start: 5px;
        }

        .ExclamationMarkIcon {
            inset-block-end: 1px;
            height: 16px;
            margin-inline-end: 10px;
            width: 16px;
            display: none;
        }

        dl {
            line-height: 20px;

            a {
                font-size: var(--header-top-menu-font-size);
                font-weight: var(--header-top-menu-font-weight);
                color: var(--header-top-menu-color);

                &:hover {
                    @include desktop {
                        text-decoration: var(--header-top-menu-text-decoration);
                    }
                }
            }
        }
    }

    &-Title {
        color: var(--header-color);
        margin: auto;
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        inset-inline-end: 0;
        text-align: center;
        text-overflow: ellipsis;
        transition-duration: 200ms;
        transition-property: opacity;
        white-space: nowrap;
        width: 100%;
        text-transform: inherit;

        @include mobile {
            @include h5;

            text-transform: capitalize;
        }

        &_isVisible {
            @include mobile {
                opacity: 1;
                max-width: calc(100% - 90px);
                left: 0;
                right: 0;
            }
        }
    }

    &-Welcome {
        display: none;
        font-size: 14px;
        margin-inline-end: 16px;
        text-align: end;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
        align-self: center;
        line-height: 16px;

        @include wide-desktop {
            display: block;
        }
    }

    &-TopMenu {
        display: flex;
        justify-content: space-between;
        margin: auto;
        max-width: var(--layout-max-width);
        padding-top: 10px;
        padding-left: var(--layout-mobile-vertical-padding);
        padding-right: var(--layout-mobile-vertical-padding);

        @include desktop {
            padding-top: 16px;
        }

        @include desktop-xxl {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-Switcher {
        display: flex;

        @include mobile {
            align-items: center;
        }
    }

    &_isCheckout {
        .Header {
            &-TopMenu {
                max-width: var(--checkout-header-max-width);
                height: var(--checkout-header-m-height);

                @include desktop {
                    height: var(--checkout-header-height);
                }
            }

            &-Nav {
                display: flex;
                padding: 0;
            }

            &-IconsWrapper {
                display: flex;
                width: 89.4%;
            }

            &-LogoWrapper {
                --header-logo-width: 150px;
                --header-logo-height: 38px;

                position: relative;
                flex-shrink: 0;
            }

            &-Button_type_account {
                display: block;
            }

            &-Button_type_close,
            &-Button_type_back {
                @include desktop {
                    display: none;
                }
            }

            &-MyAccount {
                @include desktop {
                    height: 24px;
                }

                @include mobile {
                    position: absolute;
                    inset-inline-end: 10px;
                    width: auto;
                }
            }
        }

        .MyAccountOverlay {
            @include mobile {
                inset-block-start: var(--header-total-height);
                height: calc(100vh - var(--header-total-height));
                position: fixed;
                width: 100vw;
            }
        }
    }

    &-Wrapper {
        display: flex;
        justify-content: center;
        margin-block-end: var(--header-total-height);

        @include mobile {
            z-index: 801;
        }

        &_isPrerendered {
            @include mobile {
                .Header-TopMenu,
                .Menu-MenuWrapper {
                    display: none;
                }

                .Header-Nav {
                    & > * {
                        display: none;
                    }

                    .Header {
                        &-Title,
                        &-LogoWrapper,
                        &-Button {
                            &_isVisible {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        &_isCheckout {
            --header-total-height: var(--checkout-header-m-height);

            @include desktop {
                --header-total-height: var(--checkout-header-height);
            }

            ~ .Footer {
                .Footer {
                    &-Promo,
                    &-Content {
                        display: none;
                    }

                    &-CopyrightContent {
                        padding: 18px 0;
                        justify-content: center;
                    }
                }
            }
        }

        &_name_customer_account_page,
        &_name_customer_order,
        &_name_wishlist,
        &_name_popup {
            ~ .Breadcrumbs,
            ~ .Router-MainItems {
                @include desktop {
                    background-color: var(--general-container-background-color);
                }
            }
        }

        &_name_menu_subcategory {
            @include mobile {
                .SearchField_isVisible {
                    display: none;
                }
            }
        }

        .Header-Nav {
            @include desktop {
                .Header-LogoWrapper {
                    display: none;
                }
            }
        }
    }

    &_name_popup {
        @include mobile {
            z-index: 450;
        }
    }

    &-MyAccountContainer {
        display: flex;
    }

    &-TopMenuRight {
        @include desktop {
            display: flex;
            flex-wrap: wrap;
            line-height: 17px;
        }
    }

    &-RightElements {
        @include desktop {
            display: flex;
        }

        .Header {
            &-LogoWrapper {
                @include desktop {
                    display: none;
                }
            }
        }
    }
}
