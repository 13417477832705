/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.KeyValueTable {
    margin: 0;
    min-width: 100%;

    &-HeadingContainer {
        background-color: var(--general-container-background-color);

        @include print-only {
            width: 100%;
            display: flex;
        }

        .KeyValueTable {
            &-Heading {
                @include print-only {
                    width: auto;
                }
            }

            &-Row {
                border-bottom: 0;

                @include print-only {
                    flex-wrap: wrap;
                }
            }
        }
    }

    &-Heading {
        @include subheading2;

        text-transform: uppercase;
        padding-right: 20px;
    }

    &-Content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        border-top: 0;

        .KeyValueTable-Row {
            &:hover {
                @include desktop {
                    background: var(--my-account-table-row-hover-background-color);
                }
            }
        }

        tr {
            display: inline-block;
            width: 100%;
        }
    }

    &-Row {
        width: 100%;
        display: flex;
        border-bottom: 1px solid var(--primary-divider-color);
        padding: 15px;

        @include print-only {
            flex-wrap: wrap;
        }
    }

    &-Column {
        @include paragraph;

        min-width: 130px;
        word-break: break-word;
        padding-right: 10px;

        @include desktop {
            padding-right: 20px;
        }
    }

    &-Item {
        &-Name {
            border-block-start: 1px solid rgba(10, 10, 10, .5);
        }
    }

    &-Totals {
        &-Separated {
            border-block-start: 1px solid rgba(10, 10, 10, .5);
        }
    }

    &-Footer {
        border-top: 1px solid var(--primary-divider-color);
        margin-top: 15px;
    }

    &-Wrapper {
        margin-bottom: 30px;

        @include mobile {
            overflow-x: auto;
        }
    }

    .RadioButtonIcon {
        display: none;
    }
}
