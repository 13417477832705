/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.InstallPrompt {
    border-top: 0;
    position: fixed;
    top: var(--header-total-height);
    width: 100%;
    margin: 0;
    z-index: 2;

    ~ .NotificationList {
        top: calc(var(--header-nav-height) + 94px);
    }

    ~ .Header-Wrapper {
        .Header_name_default {
            top: 50px;
        }
    }

    @include mobile {
        + .CmsPage {
            margin-block-start: 0;

            .CmsPage-Wrapper {
                margin-block-start: 0;
            }
        }
    }
}
