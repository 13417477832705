/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */
 
.HomePage {
    @include desktop {
        padding-top: 20px;
    }

    .CmsPage {
        &,
        &-Wrapper {
            margin-block-end: 0;
            max-width: none;
            padding-left: 0;
            padding-right: 0;

            @include mobile {
                margin-block-start: var(--header-total-height);
            }
        }

        &-Content {
            > p {
                margin-bottom: 0;
            }
        }

        ul,
        ol {
            li {
                list-style: none;
            }
        }
    }

    .home-slider-block,
    .home-widget-block,
    .home-product-widget {
        max-width: var(--content-wrapper-width);
        padding-left: var(--layout-mobile-vertical-padding);
        padding-right: var(--layout-mobile-vertical-padding);
        margin: auto;

        @include desktop-xxl {
            padding-left: 0;
            padding-right: 0;
        }

        &.full-width {
            max-width: none;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .home-widget-block,
    .home-product-widget {
        margin-top: 70px;

        &.home-top-categories {
            margin-top: 0;
        }

        &.home-blog {
            margin-bottom: 70px;
        }

        &.recently-viewed {
            margin-top: 0;
        }
    }
}
