/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.NoMatch {
    opacity: 0;
    animation: appear 100ms 300ms forwards;

    &-Button {
        margin-top: 15px;

        @include desktop {
            margin-top: 35px;
        }
    }

    &-Wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 30px;

        @include desktop {
            padding-top: 50px;
        }

        .CmsBlock-Wrapper {
            width: 100%;
            text-align: center;
            padding-bottom: 50px;

            h2 {
                margin-bottom: 20px;

                @include mobile {
                    display: none;
                }
            }

            p {
                margin-bottom: 0;
            }
        }
    }

    &-Image {
        max-width: 640px;

        img {
            @include desktop {
                margin-bottom: -100px;
            }
        }
    }
}
