/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --print-content-wrapper-width: 1280px;
}

.OrderPrintPage {
    min-height: 100vh;
    max-width: var(--print-content-wrapper-width);
    margin: auto;
    padding-inline: 20px;

    &-LogoWrapper {
        width: var(--logo-width);
        height: var(--logo-height);
        margin-block: 20px;
        display: block;
    }

    &-Copyright {
        display: none;
    }

    &Body {
        .NotificationList,
        .DemoNotice,
        .Header-Wrapper,
        .Breadcrumbs,
        .NavigationTabs {
            display: none;
        }
    }
}
