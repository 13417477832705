/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.Router {
    &-MainItems {
        min-height: calc(100vh - 670px);

        &:has(> .Checkout) {
            background-color: transparent;
        }
    }

    &-Loader {
        .Loader-Scale {
            position: fixed;
        }
    }

    @include desktop {
        &-Loader {
            height: calc(100vh - var(--header-total-height) - 10px);
            inset-block-start: calc(var(--header-total-height) + 10px);
        }
    }
}
