/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.CmsPage {
    &_isBreadcrumbsHidden {
        margin-block-start: calc(var(--header-total-height) + 12px);

        @include mobile {
            margin-block-start: calc(var(--header-total-height) + 14px);
        }
    }

    &-Content {
        @include mobile {
            max-width: 100%;
            overflow-x: hidden;
            padding-top: 10px;
        }

        h4 {
            margin-bottom: 10px;
        }

        ul {
            li {
                list-style-position: inside;

                &::marker {
                    color: var(--primary-light-color);
                }
            }
        }
    }

    &-Wrapper {
        grid-template-columns: auto;
        max-width: var(--content-wrapper-width);
        margin-top: 0;
        margin-bottom: 100px;
        margin-left: auto;
        margin-right: auto;
        word-wrap: break-word;
        padding-left: var(--layout-mobile-vertical-padding);
        padding-right: var(--layout-mobile-vertical-padding);

        @include desktop {
            margin-top: 0;
        }

        @include desktop-xxl {
            padding-left: 0;
            padding-right: 0;
        }

        &_page_width {
            &_full {
                max-width: 100%;
                padding-inline: 0;

                .CmsPage-Heading {
                    text-align: center;
                }
            }
        }
    }

    .Cms-Content {
        &-FullBg {
            width: 100%;
            overflow: hidden;
            height: 550px;

            @include mobile {
                height: 145px;
            }

            img {
                border-radius: 0;
                width: 100%;
                object-fit: cover;
            }
        }

        &-InfoBox {
            margin: auto;
            max-width: var(--cms-center-content-max-width);
            text-align: center;
            margin-top: 70px;

            @include mobile {
                padding: 0 11px;
            }

            h2 {
                margin-bottom: 20px;
            }

            p {
                padding: 0 80px;
                margin-bottom: 50px;

                @include mobile {
                    padding: 0 40px;
                }
            }
        }
    }

    .TwoColumn {
        &-ImageRow {
            &-Wrap {
                background: var(--color-gray9);

                h2 {
                    margin-bottom: 20px;
                }

                @include mobile {
                    padding: 0 11px;
                }
            }

            &-Inner {
                margin: auto;
                max-width: var(--cms-center-content-max-width);
                display: grid;
                grid-template-columns: auto auto;

                @include mobile {
                    grid-template-columns: auto;
                }

                &.Top {
                    @include mobile {
                        padding-top: 11px;
                    }

                    img {
                        @include mobile {
                            width: 100%;
                            height: auto;
                        }
                    }

                    > div:nth-child(1) {
                        background: url(./image/maneqinbg.svg) no-repeat right 30px;
                        background-size: contain;
                        padding: 50px 60px 100px 0;
                        margin-right: 70px;

                        @include mobile {
                            margin-right: 0;
                            padding-bottom: 55px;
                            width: 100%;
                            order: 1;
                        }
                    }
                }

                &.Bottom {
                    padding-top: 100px;

                    @include mobile {
                        padding-top: 0;
                        padding-bottom: 85px;
                    }

                    > div:nth-child(2) {
                        background: url(./image/scissorbg.svg) no-repeat right;
                        background-size: contain;
                        padding: 40px 0 0 130px;
                        width: 440px;

                        @include mobile {
                            width: auto;
                            padding: 40px 0 0 0;
                        }
                    }

                    img {
                        margin: 0 0 -40px 0;
                        z-index: 1;

                        @include mobile {
                            margin: 0;
                            width: 100%;
                            height: auto;
                        }
                    }
                }

                &-Content {
                    width: 380px;
                }

                &-Image {
                    width: 420px;

                    @include mobile {
                        width: auto;
                        text-align: center;
                    }

                    img {
                        width: auto;
                        height: auto;
                        margin-top: -40px;
                        border-radius: var(--general-image-border-radius);
                        box-shadow: -12px 16px 39px 18px rgba(0,0,0,0.2);

                        @include mobile {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    .CmsContentCenter {
        margin: auto auto 100px auto;
        max-width: var(--cms-center-content-max-width);
        text-align: center;

        @include mobile {
            margin-bottom: 50px;
        }

        p {
            padding: 0 80px;

            @include mobile {
                padding: 0 40px;
            }
        }
    }

    &-Heading {
        margin-block-end: 18px;

        @include mobile {
            margin-block-end: 21px;
            display: none;
        }
    }

    &-SectionPlaceholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        margin: 0;
        margin-block-end: 10px;
        padding-block-end: 30%;

        @include mobile {
            padding-block-end: 60%;
        }

        &:first-child {
            padding-block-end: 40%;

            @include mobile {
                padding-block-end: 100%;
            }
        }
    }

    .TextPlaceholder {
        margin-block-end: 22px;
        display: block;

        @include mobile {
            margin-block-end: 25px;
        }
    }

    .Slider-Crumbs {
        bottom: 30px;
    }

    ul,
    ol {
        li {
            list-style-position: inside;
            list-style-type: inherit;

            &::before {
                display: none;
            }
        }
    }

    .Cms-Accodian {
        border: 1px solid var(--color-gray8);
        border-radius: 5px;
        margin-top: 20px;
        margin-bottom: 50px;

        details {
            padding: 0 20px;
            border-bottom: 1px solid var(--color-gray8);

            @include mobile {
                border-top: 0;
            }

            summary {
                @include h5;

                font-weight: var(--cms-accodian-detail-font-weight);
                padding: 26px 20px 26px 0;
                cursor: pointer;

                @include top-down-icon;

                &::after {
                    position: absolute;
                    right: 0;
                    top: 33px;
                    color: var(--primary-dark-color);
                    font-size: 11px;
                }
            }

            &:last-child {
                border-bottom: 0;
            }
        }

        details[open] {
            summary {
                @include top-up-icon;

                &::after {
                    right: 0;
                    top: 33px;
                    color: var(--primary-dark-color);
                    font-size: 11px;
                }
            }
        }
    }
}
